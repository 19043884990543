 .Btn {
    font-family: 'Montserrat-Medium' !important;
    color: #ffffff !important;
    font-size: 0.8em !important;
    white-space: normal !important;
    min-width: 90px;
    width: 95%;
    padding: 5px 10px 5px 10px !important;
    margin: 5px !important;
    border-color: #2A3FAB !important;
    background-color: #2A3FAB !important;
  }
  

  .Header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 1.5rem;
}

.FilterContainer {
    display: flex;
    flex-wrap: wrap;
    font-family: 'Lato';
}

.Dropdown {
    width: 380px; 
    margin-right: 3rem; 
    font-weight: 400; 
}

.RadioButtons {
    width: 180px;
}

.SearchInput{
    width: 320px;
    position: relative;
    float: right;
    margin-top: 2rem;
}