.statute-wrapper .react-pdf__Page {
    overflow-x: visible; 
}

.react-pdf__Page__annotations {
    display: none;
}

.react-pdf__Page__textContent {
    display: none;
}
