.Header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 1.5rem;
}

.FilterContainer {
    display: flex;
    flex-wrap: wrap;
    font-family: 'Lato';
}

.Dropdown {
    width: 380px; 
    margin-right: 3rem; 
    font-weight: 400; 
}

.RadioButtons {
    width: 180px;
}

.SearchInput{
    width: 320px;
    position: relative;
    float: right;
    margin-top: 2rem;
}