/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.date-time-picker-container .react-datepicker-wrapper,
.date-time-picker-container .react-datepicker__input-container {
  width: 100%;
}

.input-text {
  min-height: 34px;
}

.no-left-border {
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
