/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

#settings-icon-toggle i {
  display: inline;
  vertical-align: middle;
  color: #2A3FAA;
  font-size: 24px;
}

#settings-icon-toggle i:hover {
  color: #b2b2b2;
}

#settings-icon-toggle .active i {
  background-color: #CC0000;
  color: #b2b2b2;
}
