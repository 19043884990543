.distributor-form {
  padding: 2% 10% 2%;
}

.distributor-form .w-100,
.distributor-form .ui-dropdown,
.distributor-form #error-msg,
.distributor-form #phone-input {
  width: 100% !important;
}

.distributor-form .ui-dropdown-filter-container {
  width: 100%;
}

.edit-distributor-form .w-100,
.edit-distributor-form .ui-dropdown,
.edit-distributor-form #error-msg {
  width: 100% !important;
}

.edit-distributor-form textarea,
.edit-distributor-form #text-area-error-msg,
.edit-distributor-form #workshop-form-save-btn {
  width: 100% !important;
}

.distributor-form h3,
.edit-distributor-form h3 {
  color: #2A3FAB;
  font-family: 'Montserrat-SemiBold';
}

@media (max-width: 992px) {
  .distributor-form .w-100,
  .distributor-form textarea,
  .distributor-form .ui-dropdown,
  .distributor-form #error-msg,
  .distributor-form #distributor-buttons {
    width: 100% !important;
  }
}

@media (max-width: 400px) {
  .distributor-form #distributor-buttons button {
    width: 100% !important;
  }

  .distributor-form #distributor-buttons {
    text-align: center !important;
  }

  .edit-distributor-form #distributor-buttons {
    text-align: left !important;
  }
}
