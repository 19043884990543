.ContainerAccept {
  width: 75%;
  min-height: 200px;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 12px -4px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0 5px 12px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 5px 12px -4px rgba(0, 0, 0, 0.75);
  background-color: #bee3be;
  border: 1px solid #408140;
  padding: 20px;
  text-align: center;
}

.ContainerWarning {
  width: 75%;
  min-height: 200px;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 12px -4px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0 5px 12px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 5px 12px -4px rgba(0, 0, 0, 0.75);
  background-color: #f4cbca;
  border: 1px solid #983a37;
  padding: 20px;
  text-align: center;
}

.ContainerDescription {
  width: 75%;
  min-height: 200px;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 12px -4px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0 5px 12px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 5px 12px -4px rgba(0, 0, 0, 0.75);
  background-color: #fff;
  border: 2px solid #2A3FAB;
  padding: 30px;
  text-align: center;
}

.MessageAccept {
  font-size: 20px;
  color: #408140;
}

.MessageWarning {
  font-size: 20px;
  color: #983a37;
}
