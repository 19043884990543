/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.dropdown-content {
  border-radius: 0 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  box-shadow: none !important;
}

.dropdown-content label {
  padding-right: 40px !important;
  text-overflow: ellipsis;
  font-family: 'Montserrat-Regular';
  font-size: 16px !important;
  color: #000000;
}

.dropdown-content .p-dropdown-trigger {
  padding-top: 2px !important;
  border-left: 0 !important;
  color: #666666;
}
.field-label {
  margin-bottom: 3px;
}

.dropdown-content .p-dropdown:focus {
  box-shadow: none !important;
  border-bottom: 1px solid #2A3FAB;
}

.dropdown-content:hover {
  border-bottom: 1px solid #2A3FAB !important;
}

.dropdown-content .p-dropdown-clear-icon {
  color: #CC0000 !important;
  font-size: 1rem !important;
  right: 2rem !important;
}
