.content {
  padding: 60px 0 0 260px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .content {
    padding-left: 0;
  }
}
