/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.p-float-label input.p-state-filled-hack ~ label {
  top: -0.75em !important;
  font-size: 12px !important;
}
