.Container {
  overflow-x: auto;
}

.Header {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.GlobalFilter {
  width: 20%;
  min-width: 150px;
}

.DotRed {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #CC0000;
  margin: 0 auto;
}

.DotGreen {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #458a43;
  margin: 0 auto;
}

.WarrantyBtn {
  font-family: 'Montserrat-Medium' !important;
  color: #ffffff !important;
  font-size: 0.8em !important;
  white-space: normal !important;
  min-width: 90px;
  width: 95%;
  padding: 5px 10px 5px 10px !important;
  margin: 5px !important;
  border-color: #2A3FAB !important;
  background-color: #2A3FAB !important;
}