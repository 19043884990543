.user-profile-container {
  margin: 2rem 2rem 4rem 2rem;
  width: 650px;
}

.profile-data {
  padding: 2rem;
}

.profile-data form input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.profile-data .form-control {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.profile-data .error-msg {
  font-size: small;
}

.profile-data img:hover {
  cursor: pointer;
}

.thin-user-tip {
  width: 90%;
  margin: 0 5% 0 5%;
}

.profile-data .ui-dropdown,
.profile-data .p-dropdown-panel .p-dropdown-filter-container {
  width: 100% !important;
}

.user-data-container {
  font-family: 'Montserrat-Regular';
  font-size: 16px;
  word-wrap: break-word;
  line-height: 1.2;
}

.user-data-container-error {
  word-wrap: break-word;
  line-height: 1.2;
  font-family: 'Montserrat-Regular';
  font-size: 16px;
  color: #CC0000;
  text-align: center !important;
  margin: 10px 0 20px 0;
}

.user-data-container-big {
  font-family: 'Montserrat-Regular';
  font-size: 18px;
  word-wrap: break-word;
  line-height: 1.5;
}

.user-data-label {
  font-family: 'Montserrat-SemiBold' !important;
}

@media (max-width: 992px) {
  .user-profile-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
