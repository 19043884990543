/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.promotion-container {
  border: none;
  min-width: 50%;
}

.promotion-container .modal-content {
  min-height: 600px !important;
}

.promotion-header {
  justify-content: center !important;
}

.promotion-title {
  margin-bottom: 0;
}

.close-promotion-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.page-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
}

.right-btn {
  float: right;
}

.promotion-image {
  height: auto;
  width: auto;
  max-height: 100%;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.promotion-description {
  margin-top: 2rem;
}

.promotion-end-date {
  color: grey;
  font-size: smaller;
  margin-bottom: 50px;
}
