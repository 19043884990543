.Container {
  padding: 32px 82px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.FirstPart {
  width: 50%;
}

.SecondPart {
  min-width: 800px;
}

.ThirdPart {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}

@media (max-width: 1170px) {
  .ThirdPart {
    width: 100%;
    grid-template-columns: 1fr;
    column-gap: 0;
  }

  .FirstPart {
    width: 100%;
  }
}

.ButtonBox {
  width: 100%;
  text-align: right;
}
