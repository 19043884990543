.content-wrapper ul {
  padding: 0;
  list-style-type: none;
}

.content-wrapper a {
  padding: 10px 10px 10px 20px;
  text-decoration: none !important;
  outline: 0;
  width: 100% !important;
  color: #2A3FAA !important;
  display: flex;
  border-left: 4px solid transparent;
}

.content-wrapper a::before {
  margin-top: auto;
  margin-bottom: auto;
  min-width: 40px;
}

.content-wrapper a:hover {
  background-color: #2A3FAA;
  color: white !important;
}

.content-wrapper a > p {
  margin-left: 8px;
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  vertical-align: middle;
  margin-top: auto !important;
  margin-bottom: auto !important;
  text-align: left !important;
  line-height: 1.5;
  display: flex;
}

.content-wrapper a > p > i {
  margin-left: 6px;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.content-wrapper .active {
  background-color: #CC0000;
  color: #ffffff !important;
  border-left: 4px solid #CC0000;
}

.content-wrapper .user-collapse {
  display: none;
}

@media (max-width: 768px) {
  #user-avatar {
    display: none;
  }

  .content-wrapper .user-collapse {
    display: block;
  }
}
