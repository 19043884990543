.custom-text-area {
  width: 100%;
  font-family: 'Montserrat-Regular' !important;
  font-size: 16px !important;
  color: #000000 !important;
}

.custom-text-area:focus {
  box-shadow: none !important;
  border: 1px solid #2A3FAB !important;
}

.custom-text-area:enabled:hover {
  border: 1px solid #2A3FAB !important;
}

.custom-text-area .form-control:disabled,
.form-control[readonly] {
  color: #d4d4db;
  opacity: 0.35 !important;
  background-color: #ffffff !important;
}
