table td,
table thead tr th {
  font-size: smaller;
  padding: 5px !important;
}

.p-datatable-tbody .p-cell-data,
.p-datatable-emptymessage {
  color: #333333;
  line-height: 1;
  font-size: 14px;
  font-family: 'Montserrat-Light';
}

.p-datatable-emptymessage td {
  background-color: white !important;
}

.p-datatable .p-column-title {
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat-Bold';
  word-break: break-all;
}

.p-datatable-thead th {
  border: none;
  border-bottom: #c6c6c6 solid 2px;
  background-color: white;
  text-align: left;
  font-weight: bold;
  font-family: 'Roboto', 'Trebuchet MS', Arial, Helvetica, sans-serif;
  font-size: 1em;
}

.p-datatable th.ui-state-active {
  color: #000000;
}

.p-widget-header {
  border: none;
  background: transparent !important;
}

.p-widget-header .btn-secondary {
  background-color: #22ac92;
  border-color: #22ac92;
}

.p-widget-header .btn-secondary:hover {
  border-color: #22baa0;
  background-color: #22baa0;
}

.ui-widget-content {
  border: none !important;
}

.p-datatable-tbody td {
  border: none !important;
  word-wrap: break-word;
  font-size: 14px;
  font-family: 'Montserrat-Light';
}

.p-datatable-tbody tr {
  border: none !important;
}

tr td:nth-child(1) {
  background-color: #f1f1f1;
}

tr {
  background-color: #f9f9f9 !important;
}

.p-row-odd td:nth-child(1) {
  background-color: #f9f9f9 !important;
}

.p-row-odd {
  background-color: white !important;
}
.p-datatable-header {
  background-color: white !important;
  padding: 0 !important;
  border: none !important;
}
tr:hover,
tr .p-row-odd:hover {
  cursor: pointer;
  background-color: #f5f5f5 !important;
}

tr:hover td:nth-child(1),
tr .p-row-odd:hover td:nth-child(1) {
  background-color: #eaeaea !important;
}

.lp-col {
  width: 13%;
}

.email-col {
  width: 35%;
}

/*odd red rows*/
.red-row:nth-child(odd) td:nth-child(1) {
  background-color: rgba(255, 0, 0, 0.08) !important;
}

.red-row:nth-child(odd) {
  background-color: rgba(255, 0, 0, 0.08) !important;
}

.red-row:nth-child(odd):hover {
  cursor: pointer;
  background-color: rgba(255, 0, 0, 0.2) !important;
}

.red-row:nth-child(odd):hover td:nth-child(1) {
  background-color: rgba(255, 0, 0, 0.2) !important;
}

/*even red row*/
.red-row:nth-child(even) td:nth-child(1) {
  background-color: rgba(255, 0, 0, 0.12) !important;
}

.red-row:nth-child(even) {
  background-color: rgba(255, 0, 0, 0.12) !important;
}

.red-row:nth-child(even):hover {
  cursor: pointer;
  background-color: rgba(255, 0, 0, 0.2) !important;
}

.red-row:nth-child(even):hover td:nth-child(1) {
  background-color: rgba(255, 0, 0, 0.2) !important;
}

/*odd green rows*/
.green-row:nth-child(odd) td:nth-child(1) {
  background-color: rgba(0, 255, 0, 0.08) !important;
}

.green-row:nth-child(odd) {
  background-color: rgba(0, 255, 0, 0.08) !important;
}

.green-row:nth-child(odd):hover {
  cursor: pointer;
  background-color: rgba(0, 255, 0, 0.2) !important;
}

.green-row:nth-child(odd):hover td:nth-child(1) {
  background-color: rgba(0, 255, 0, 0.2) !important;
}

/*even green row*/
.green-row:nth-child(even) td:nth-child(1) {
  background-color: rgba(0, 255, 0, 0.12) !important;
}

.green-row:nth-child(even) {
  background-color: rgba(0, 255, 0, 0.12) !important;
}

.green-row:nth-child(even):hover {
  cursor: pointer;
  background-color: rgba(0, 255, 0, 0.2) !important;
}

.green-row:nth-child(even):hover td:nth-child(1) {
  background-color: rgba(0, 255, 0, 0.2) !important;
}

/*odd orange rows*/
.orange-row:nth-child(odd) td:nth-child(1) {
  background-color: rgba(255, 153, 0, 0.08) !important;
}

.orange-row:nth-child(odd) {
  background-color: rgba(255, 153, 0, 0.08) !important;
}

.orange-row:nth-child(odd):hover {
  cursor: pointer;
  background-color: rgba(255, 153, 0, 0.2) !important;
}

.orange-row:nth-child(odd):hover td:nth-child(1) {
  background-color: rgba(255, 153, 0, 0.2) !important;
}

/*even orange row*/
.orange-row:nth-child(even) td:nth-child(1) {
  background-color: rgba(255, 153, 0, 0.12) !important;
}

.orange-row:nth-child(even) {
  background-color: rgba(255, 153, 0, 0.12) !important;
}

.orange-row:nth-child(even):hover {
  cursor: pointer;
  background-color: rgba(255, 153, 0, 0.2) !important;
}

.orange-row:nth-child(even):hover td:nth-child(1) {
  background-color: rgba(255, 153, 0, 0.2) !important;
}

.bold-row {
  font-weight: bold;
}

.ui-row-toggler {
  font-size: 2em !important;
}

.expander-col {
  width: 5em;
}

.expanded-datatable th .ui-column-title {
  display: block !important;
}

.expandable-datatable td[colspan] {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-style: solid !important;
  border-width: 0 1px 0 1px !important;
  border-color: #f1f1f1 !important;
}

.working-hours-datepicker .ui-inputtext {
  background-color: transparent !important;
}

.data-table-button {
  margin-right: 0 !important;
}

.data-table-padding-class {
  padding: 30px 2rem 30px 2rem;
}

@media (max-width: 991px) {
  .data-table-padding-class {
    padding: 30px 1rem 30px 1rem;
  }

  .data-table-button {
    right: 1rem;
  }
}

@media (max-width: 576px) {
  .expander-col .ui-column-title {
    display: none !important;
  }
}
