/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.start-page-wrapper {
  width: 600px;
  text-align: center;
  margin: 3rem auto 3rem auto;
}

.start-page-wrapper #valuation-btn {
  background-color: #2A3FAB;
  border-color: #2A3FAB;
  margin-top: 36px;
  max-width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 40px;
  padding-left: 40px;
  font-family: 'Montserrat-Bold';
  font-size: 16px;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .start-page-wrapper {
    width: 90%;
  }
}
