/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.subsection-tiles-container {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
  margin-top: 2rem !important;
}

.subsection-tile {
  flex-direction: column;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  text-align: center;
  font-family: 'Montserrat-Bold';
  font-size: 16px;
  color: #99A0AD;
  border-radius: 10px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 16px;
  justify-items: left;
  align-items: center;
}

.subsection-tile:hover {
  background-color: #D8DFED;
  color: #2A3FAA;
}

.subsection-tile-active {
  background-color: #D8DFED;
  color: #2A3FAA;
}

.subsection-tile-disabled {
  background-color: #6c757d;
}
