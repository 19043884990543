.datePickerRadioButtonGroupWrapper .col-12:nth-child(1){
  display: none;
}

.datePickerRadioButtonGroupWrapper .fromToDatePicker {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.datePickerRadioButtonGroupWrapper .fromToDatePicker .dash {
  width: 40px;
  margin-left: 0;
  text-align: center;
  font-weight: normal;
}

.datePickerRadioButtonGroupWrapper .react-datepicker-wrapper{
  width: 200px;
}

.datePickerRadioButtonGroupWrapper .react-datepicker-wrapper input{
  height: calc(20px + 1rem);
}

.datePickerRadioButtonGroupWrapper #error-msg{
  display: none;
}

.datePickerRadioButtonGroupWrapper .mt-2{
  margin-top: 0rem !important;
}