.parts-wrapper button {
  border-color: #22ac92;
  background-color: #22ac92;
}

.parts-wrapper button:hover:enabled {
  border-color: #22baa0;
  background-color: #22baa0;
}

.parts-content {
  display: inline-block;
  padding-left: 15px;
}

#parts-list .ui-datatable-even:hover td:nth-child(n) {
  background-color: white !important;
  cursor: auto !important;
}

#parts-list .ui-datatable-even td:nth-child(n) {
  background-color: white !important;
}

#parts-list .ui-datatable-odd:hover td:nth-child(n) {
  background-color: white !important;
  cursor: auto !important;
}

#parts-list .ui-datatable-odd td:nth-child(n) {
  background-color: white !important;
}

#parts-list .ui-datatable tfoot td.ui-state-default {
  border-top: 1px solid #c6c6c6;
  padding: 0 !important;
}

.needed-parts-custom-column {
  padding: 0 !important;
}

.needed-parts-custom-column .ui-dropdown {
  width: 90% !important;
  margin-left: 5%;
}

.needed-parts-custom-column .ui-cell-data {
  width: 100% !important;
}

.needed-parts-edit-btn {
  width: 90%;
  margin: 5%;
}
