/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.service-edit-form .ui-dropdown {
  width: 100% !important;
}

.service-edit-form h2 {
  font-family: 'Montserrat-Semibold';
  color: #2A3FAB;
}
