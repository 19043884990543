.jodit-toolbar__box{
    position: sticky;
    top: 90px !important;
    z-index: 2 !important;
    background-color: white;
}

.jodit-workplace{
  background-color: white;
}

@media (max-width: 395px) {
  .jodit-status-bar {
    height: 50px !important;
}
}