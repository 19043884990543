.cartQuantityInputWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartQuantityInputWrapper .p-inputnumber-input {
  text-align: center;
  width: 80px;
  font-family: 'Montserrat-Regular' !important;
  font-size: 14px !important;
}

.cartQuantityInputWrapper .button {
  padding: 0 20px;
  font-family: 'Montserrat-Regular' !important;
  font-size: 18px !important;
  cursor: pointer;
  padding-bottom: 2px;
}

.cartQuantityInputWrapper .button.disabled {
  cursor: default;
}
