.Container {
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.paragraph {
  font-size: 14px;
  font-family: 'Montserrat-Regular';
}

.paragraphBold {
  font-size: 14px;
  font-weight: bold;
  font-family: 'Montserrat-Regular';
}

.buttonPanel {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
