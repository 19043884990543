hr {
  border-width: 2px !important;
}

.workshop-form #search-box {
  margin-top: 10px;
  position: relative;
  background: #fff;
  border-radius: 2px;
  height: 40px;
  width: 50%;
  border: 1px solid transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
  transition-duration: 0.3s;
}

.workshop-form button {
  border-color: #22ac92;
  background-color: #22ac92;
}

.workshop-form button:hover:enabled {
  border-color: #22baa0;
  background-color: #22baa0;
}

.workshop-form {
  padding: 2% 10% 2%;
}

.workshop-form .w-100,
.workshop-form .ui-dropdown,
.workshop-form #error-msg,
.workshop-form #phone-input {
  width: 100% !important;
}

.workshop-form .p-dropdown-filter-container {
  width: 100%;
}

.update-workshop-form .w-100,
.update-workshop-form .ui-dropdown,
.update-workshop-form #error-msg {
  width: 100% !important;
}

.update-workshop-form textarea,
.update-workshop-form #text-area-error-msg,
.update-workshop-form #workshop-form-save-btn {
  width: 100% !important;
}

@media (max-width: 400px) {
  .workshop-form .w-100,
  .workshop-form .ui-dropdown,
  .workshop-form #error-msg {
    width: 75% !important;
  }

  .workshop-form #workshop-form-save-btn button {
    width: 100% !important;
  }

  .workshop-form #workshop-form-save-btn {
    text-align: center !important;
  }

  .update-workshop-form #workshop-form-save-btn button {
    width: -webkit-calc(75% + 3em) !important;
    width: calc(75% + 3em) !important;
  }

  .update-workshop-form #workshop-form-save-btn {
    text-align: left !important;
  }
}
