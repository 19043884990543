/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

#toggle-create-service-btn {
  width: -webkit-calc(14rem + 10px) !important;
  width: calc(14rem + 10px) !important;
}
