/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.schedule-container {
  height: calc(
    100vh - 60px - 25px - 2rem
  ); /*Viewport - header - footer - container margins*/
}
