/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.number-format-field {
  background-color: #ffffff;
  border-radius: 0 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #c0c0c0;
  font-family: 'Montserrat-Regular' !important;
  font-size: 16px !important;
  color: #000000 !important;
}

.number-format-field:focus {
  box-shadow: none !important;
  border-bottom: 1px solid #2A3FAB;
}

.number-format-field:hover {
  border-bottom: 1px solid #2A3FAB !important;
}

.input-disabled {
  color: #a5a5a5 !important;
  border-bottom: 1px solid #f0f0f0 !important;
}

.input-disabled:hover {
  border-bottom: 1px solid #cfcaca !important;
}
