.Btn {
  font-family: 'Montserrat-Medium' !important;
  color: #ffffff !important;
  font-size: 0.8em !important;
  white-space: normal !important;
  min-width: 90px;
  width: 40%;
  padding: 5px 10px 5px 10px !important;
  margin: 5px !important;
  border-color: #2A3FAB !important;
  background-color: #2A3FAB !important;
}

.NewProductBox {
  justify-content: center; 
  display: flex;
  flex-direction: column;
  align-items: center
}