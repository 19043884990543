/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością

 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.content-wrapper-container {
  border-radius: 10px;
  background-color: #ffffff;
}

.content-wrapper-title {
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  background-color: #fcfcfc;
  color: #222220;
  text-align: center;
  min-height: 70px;
  padding: 23px 2rem 23px 2rem;
  border-bottom: 1px solid #eee;
}

.content-wrapper-title i {
  margin-right: 16px;
}

.content-wrapper-title span {
  font-family: 'Montserrat-Bold' !important;
  font-size: 24px;
  line-height: 1;
}

.content-wrapper-title img {
  margin-right: 26px;
}

.content-wrapper-content {
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.default-content-container {
  margin: 2rem 2rem 4rem 2rem;
}

.container-with-flex {
  display: flex;
  flex-direction: column;
}

.content-with-flex {
  flex: 1;
}
