.workshop-sign-up-container {
  margin: 3rem auto 3rem auto;
  width: 65%;
}

.workshop-sign-up-container .card-header {
  text-align: center;
  font-weight: bold;
  font-size: large;
  color: white;
  background-color: #22baa0;
}

.workshop-sign-up-container button {
  background-color: #22baa0;
  border-color: #22baa0;
}

.workshop-sign-up-container button:hover:enabled {
  background-color: #22bab0;
  border-color: #22bab0;
}

.workshop-sign-up-container .form-control {
  width: 100%;
  padding: 0;
}

.workshop-sign-up-container #error-msg {
  width: 100%;
}

.workshop-sign-up-container .error-msg span {
  font-size: small;
}

.workshop-sign-up-container form {
  margin: 0 10%;
}

.workshop-sign-up-container .terms-text {
  width: 100%;
}

.workshop-sign-up-container .captcha-container .row {
  display: block !important;
}

@media (max-width: 768px) {
  .workshop-sign-up-container {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .workshop-sign-up-container form {
    margin: 0 5%;
  }
}

@media (max-width: 350px) {
  .workshop-sign-up-container .captcha-container {
    transform: scale(0.83);
    transform-origin: 0 0;
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media (max-width: 300px) {
  .workshop-sign-up-container form {
    margin: 0 2%;
  }
}
