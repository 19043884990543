.Container {
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ColapsableSection {
  background-color: #2c5c9a;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 5px 15px;
}

.Toggle {
  width: 25px;
  height: 25px;
  border-radius: 13px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: #ffffff;
  transition: transform 0.3s ease-out;
}

.ToggleRotate {
  width: 25px;
  height: 25px;
  border-radius: 13px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: #ffffff;
  transition: transform 0.3s ease-out;
  transform: rotate(180deg);
}

.ColapseBox {
  width: 100%;
}
.Title {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  margin: 0 15px;
}

.Entry {
  width: 100%;
  font-weight: bold;
  margin: 8px;
}

.EntryCenter {
  width: 100%;
  font-weight: bold;
  margin: 8px;
  display: flex;
  align-items: center;
}

.EntryData {
  font-weight: normal;
}

.RedDot {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #CC0000;
  margin-left: 10px;
}

.GreenDot {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #458a43;
  margin-left: 10px;
}
