/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością

 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.login-btn {
  background-color: #2A3FAB !important;
  border-radius: 5rem !important;
  font-family: 'Montserrat-Bold';
  font-size: 16px;
  border: none !important;
  opacity: 1 !important;
  color: white;
}

.login-btn:hover {
  cursor: pointer;
  background-color: #233968 !important;
}

.login-register-link {
  font-family: 'Montserrat-Medium';
  font-size: 12px;
  color: #000000;
  text-align: center;
}

.login-container-col .p-inputtext {
  color: #2A3FAB;
}

.login-container-col .btn-link {
  font-family: 'Montserrat-Medium';
  font-size: 12px;
  color: #2A3FAB !important;
}

.login-container-col .btn-link:hover {
  color: #233968 !important;
}

.login-register-link span {
  cursor: pointer;
}

.google-btn {
  background-color: #d34023 !important;
  border-color: #d34023 !important;
}

.google-btn:hover {
  background-color: #cd4534 !important;
  border-color: #cd4534 !important;
}

.fb-btn {
  background-color: #2c5c9a !important;
  border-color: #2c5c9a !important;
}

.fb-btn:hover {
  background-color: #3a4a87 !important;
  border-color: #3a4a87 !important;
}

.social-button {
  display: flex !important;
  align-self: space-around !important;
  align-items: center !important;
  margin: auto !important;
}

.social-icon {
  padding-right: 5px;
  border-right: 1px solid white;
}

.social-button-label {
  margin-left: 5px;
  font-family: 'Montserrat-Medium';
  font-size: 10px;
}

.modal-backdrop.show {
  opacity: 0.2;
}

.modal-content {
  border: none !important;
}

.modal-content .btn-primary {
  background-color: #22baa0 !important;
  border-color: #22baa0 !important;
}
.modal-content .btn-primary:hover {
  background-color: #22ac92 !important;
  border-color: #22ac92 !important;
}

.addon-width {
  width: 3em;
}
