/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

#page-minus,
#page-plus,
#mark-statute-valid-btn {
  border-color: #22ac92;
  background-color: #22ac92;
}

#page-minus:hover:enabled,
#page-plus:hover:enabled,
#mark-statute-valid-btn:hover:enabled {
  border-color: #22baa0;
  background-color: #22baa0;
}

.react-pdf__Document .react-pdf__Page {
  overflow-x: auto;
  border-color: black;
  border-style: solid;
  border-width: 1px;
}
