/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.category-header {
  cursor: pointer;
  background-color: #f0f0f0;
}

.category-header #description {
  font-size: smaller;
}

.category-header #categoryButtons {
  justify-content: space-between;
}

.category-header:hover {
  background-color: #e1e1e1;
}

.decision-btn {
  width: 2.5rem !important;
  height: 2.5rem !important;
  min-width: 2.5rem !important;
  min-height: 2.5rem !important;
}

.decision-buttons {
  font-size: smaller;
  word-break: keep-all;
}

.pending-services-info {
  font-size: smaller;
  color: #dc3545;
}

.category-header #toggle-icon {
  color: #2A3FAB !important;
}

#categoryButtons {
  display: flex;
  align-items: center;
}

@media (max-width: 1400px) {
  #categoryButtons {
    flex-direction: column;
    text-align: center;
  }
}
