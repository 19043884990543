/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.language-select {
  text-align: right;
}

#language-select-toggle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  justify-items: center;
  align-items: center;
}

.language-select ul {
  margin-top: 16px;
  line-height: 30px !important;
  width: 200px;
  float: right;
  list-style-type: none;
  font-size: 14px;
  text-align: left;
  padding-left: 0;
  border: 2px solid #9e9e9d;
  border-radius: 0.5rem;
  background-color: white;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Montserrat-SemiBold';
}

.language-select li {
  border-bottom: #dddfe2;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.language-select li:hover {
  background-color: #eff1f4;
}

#language-select-toggle {
  color: #2A3FAA !important;
  text-align: center !important;
  font-family: 'Montserrat-SemiBold';
  font-size: 14px;
  line-height: 1;
}

#language-select-toggle i {
  font-size: 24px;
}

#language-select-toggle i:hover {
  color: #b2b2b2;
}

#language-select-toggle i.active {
  background-color: #fcfcfc;
  color: #b2b2b2;
}

.language-select-collapse.collapse.show,
.language-select-collapse.collapsing {
  position: absolute;
  right: 15px;
}
