@import url('./assets/fonts/fonts.css');

.pac-container {
  z-index: 1000000;
}

body {
  font-family: 'Lato' !important;
  background-color: #eeeeee!important;
  margin: 0;
  padding: 0;
}

.p-component-overlay.p-sidebar-mask {
 z-index: 2 !important;
}

.p-growl {
  top: 65px !important;
}

.p-growl-message-success {
  background-color: #22baa0 !important;
}

@media (max-width: 576px) {
  .ui-growl {
    width: 70% !important;
  }
}

.root-panel {
  background-color: white;
}

.flex {
  display: flex !important;
}

.error-color {
  color: #CC0000 !important;
}

.error-info {
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  color: #CC0000;
}

.shadow {
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
}

.bottom-shadow {
  /*
  -webkit-box-shadow: 0 0 4px 0 #bbbbbb;
  -moz-box-shadow: 0 0 4px 0 #bbbbbb;
  box-shadow: 0 0 4px 0 #bbbbbb;
  */
}

.field-label {
  font-family: 'Montserrat-Regular' !important;
  font-size: 12px !important;
  color: #7e838c;
}

.read-only-label {
  font-family: 'Montserrat-SemiBold' !important;
  font-size: 16px !important;
}

input[type='checkbox'] {
  outline: none;
}

.checkbox-label {
  font-family: 'Montserrat-Regular' !important;
  font-size: 10px !important;
  margin-left: 10px;
}

.checkbox-label a {
  color: #000000 !important;
}

.vertical-centered {
  display: flex;
  align-items: center;
}

.back-btn {
  cursor: pointer;
  color: gray;
}

.back-btn:hover {
  color: #22baa0;
}

.back-btn-col {
  display: flex;
  align-items: center;
}

@media (max-width: 576px) {
  .back-btn-col {
    justify-content: center;
  }
}

.main-btn {
  font-family: 'Montserrat-Bold' !important;
  color: #ffffff !important;
  font-size: 15px !important;
  min-width: 90px;
  padding: 5px 10px 5px 10px !important;
  margin: 5px !important;
  border-color: #2A3FAB !important;
  background-color: #2A3FAB !important;
}

.main-btn:hover:enabled {
  border-color: #203157 !important;
  background-color: #203157 !important;
}

.main-btn:disabled {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.btn-danger {
  font-family: 'Montserrat-Medium' !important;
  color: #ffffff !important;
  font-size: 15px !important;
  min-width: 90px !important;
  padding: 5px 10px 5px 10px !important;
  margin: 5px !important;
  background-color: #CC0000 !important;
  border-color: #CC0000 !important;
}

.btn-danger:hover {
  background-color: #ac221b !important;
  border-color: #ac221b !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.radio-button .ui-radiobutton-box {
  border-color: #b5b5b5 !important;
  background-color: #ffffff !important;
}

.radio-button .ui-radiobutton-icon {
  color: #2A3FAB !important;
}

.radio-button-label {
  margin-left: 0.5rem;
  font-family: 'Montserrat-Regular';
  font-size: 16px;
  color: #000000;
}

.type-tile {
  background-color: white;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  text-align: center;
}

@media (min-width: 1025px) {
  .type-tile:hover {
    background-color: #22baa0;
    color: white;
  }
}

.active-type-tile {
  background-color: #22baa0;
  color: white;
}

.font-bold {
  font-size: 1rem;
  font-weight: bold;
}

.ui-widget-overlay {
  z-index: 1000 !important;
}

.pi {
  font-weight: bold !important;
}

#update-data-sidebar {
  z-index: 1001 !important;
}

.modal-backdrop {
  z-index: 1003 !important;
}

.modal {
  z-index: 1004 !important;
}

.modal-content {
  z-index: 1005 !important;
}

.fixed-width-btn {
  width: 7rem;
}

.fixed-width-big-btn {
  width: 10rem;
}

.fixed-width-xbig-btn {
  width: 14.5rem;
}

.not-clickable {
  pointer-events: none;
}

.version-footer-container {
  width: 100%;
  height: 30px;
  position: fixed;
  bottom: 0;
  padding-right: 20px;
  background-color: #fcfcfc;
  box-shadow: 0 0 4px 0 #bbbbbb;
  z-index: 2;
}

.version-footer-content {
  height: 100%;
  width: auto;
  float: right;
  color: #575756;
  line-height: 30px;
  font-size: 12px;
  font-family: 'Montserrat-Regular';
}

.fixed-input-addon {
  width: 3em;
}

.ui-placeholder {
  color: #788087 !important;
}

.ui-chkbox-box {
  height: 25px !important;
  width: 25px !important;
  border: 2px solid #c0c0c0 !important;
  font-size: 20px !important;
}

.custom-tooltip,
.ui-tooltip-text {
  max-width: none !important;
  background-color: #f4917d !important;
  border: 1px solid #f4917d !important;
  color: white !important;
  font-size: 16px !important;
  font-family: 'Lato' !important;
  text-align: center;
  display: inline-block;
  word-wrap: break-word;
}

.custom-tooltip-smaller-width,
.ui-tooltip-text {
  width: 25vw !important;
}

.tooltip .arrow::before,
.ui-tooltip-arrow {
  border-top-color: #f4917d !important;
}

.break-all {
  word-break: break-all;
}

.hyphens {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
