
.customRadioButtonWrapper {
  margin-top: 0.5rem;
}

.customRadioButtonWrapper .customRadioButtonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: calc(23px + 1rem);
}

.customRadioButtonWrapper .customRadioButtonRow >span{
  font-weight: normal;
  margin-left: 9px;
  width: 180px;
}