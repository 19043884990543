.nav-header {
  position: fixed;
  width: 100%;
  background-color: #fcfcfc;
  height: 60px;
  z-index: 1000;
  box-shadow: 0 0 4px 0 #bbbbbb;
}

.logo {
  display: block;
  float: left;
  background-color: #fcfcfc;
  height: 50px;
  padding-left: 30px;
  padding-top: 2px;
}

.logo-margin {
  padding-left: 180px;
}

.logo .logo-content {
  line-height: 56px;
  align-items: center;
  font-weight: bold;
  color: white;
}

.nav-header .wrapper {
  float: right;
  margin-top: 10px;
  padding: 0 20px;
  color: #5f5f5f;
}

.nav-header .wrapper a {
  text-decoration: none;
  cursor: pointer;
  color: #5f5f5f;
}

.nav-header .wrapper a:hover {
  color: #5f5f5f;
}

.user-data {
  display: block;
  cursor: pointer;
  padding-right: 20px;
  float: right;
}

.login-logout {
  margin-top: -5px;
  color: #2A3FAA;
  display: block;
  line-height: 1;
  text-align: center;
}

.login-logout i {
  font-size: 24px;
}

.login-label {
  font-family: 'Montserrat-SemiBold';
  font-size: 12px;
}

.bm-menu-wrap {
  width: 260px !important;
}

.bm-burger-button {
  display: none;
  position: fixed;
  width: 36px;
  height: 30px;
  left: 15px;
  top: 15px;
}

.hidden {
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #2A3FAA;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  text-align: center;
  background: #fff;
  padding-top: 1.6em;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

.align-horizontal {
  position: relative;
  top: -2px;
}

@media (max-width: 768px) {
  .menu-toggler,
  .bm-burger-button {
    display: block;
  }

  .user-data {
    display: none;
  }

  .logo-margin {
    padding-left: 40px;
  }
}

@media (max-width: 510px) {
  .logo {
    display: none;
  }
}
