/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.loyalty-program-info-row {
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
}

.loyalty-program-info-row-active {
  background-color: rgba(0, 255, 0, 0.2);
}
