.RowContainer {
  display: inline-block;
}

.Checkbox {
  margin-right: 20px;
  justify-content: center;
  display: inline-block;
}

.CheckboxLabel {
  margin-bottom: 0;
  margin-left: 10px;
}

.SeverityLabel {
  margin-top: 20px;
  margin-bottom: 10px;
}

.RadioButton {
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.ColorBox {
  width: 24px;
  height: 24px;
  border-radius: 3px;
  margin-left: 10px;
}
