/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.valuation-inquiry-form .ui-dropdown,
.valuation-inquiry-form .ui-dropdown-filter-container {
  width: 100% !important;
}

.valuation-inquiry-form #search-box {
  margin-top: 10px;
  position: absolute;
  background: #fff;
  border-radius: 2px;
  height: 30px;
  width: 300px;
  border: 1px solid transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
  transition-duration: 0.3s;
}

.valuation-inquiry-form .no-left-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.add-service-section-btn {
  background-color: transparent !important;
  border: none !important;
  color: #2A3FAB !important;
  font-size: 10px !important;
  font-family: 'Montserrat-Medium' !important;
  line-height: 20px !important;
  display: flex !important;
  box-shadow: none !important;
}

.add-service-section-btn i {
  font-size: 20px !important;
  margin-left: 5px;
}

.delete-service-section-btn {
  font-size: 10px !important;
  font-family: 'Montserrat-Medium' !important;
  color: #ffffff;
  background-color: #CC0000 !important;
  border-color: #CC0000 !important;
  line-height: 20px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.delete-service-section-btn i {
  margin-right: 5px;
  font-size: 20px !important;
}

.form-tooltip {
  width: 55vw !important;
  background-color: #f4917d !important;
  border: 1px solid #f4917d !important;
  color: white !important;
  font-size: 16px !important;
  font-family: 'Lato' !important;
  text-align: center;
  word-wrap: break-word;
  left: 10% !important;
}

.form-tooltip::after {
  border-top-color: #f4917d !important;
}

.form-tooltip-short {
  width: 20vw !important;
  background-color: #f4917d !important;
  border: 1px solid #f4917d !important;
  color: white !important;
  font-size: 16px !important;
  font-family: 'Lato' !important;
  text-align: center;
  word-wrap: break-word;
}

.form-tooltip-short::after {
  border-top-color: #f4917d !important;
}

.valuation-inquiry-form-radius-btn {
  min-width: 150px !important;
}

.accept-statute-container {
  width: 100%;
  min-height: 40px;
}

.accept-statute-content {
  display: flex;
  align-items: center;
  float: right;
}

@media (max-width: 991px) {
  .form-tooltip {
    width: 90% !important;
    left: 10px !important;
  }
}
