.Wrapper {
  width: 100%;
  margin-bottom: 10px;
}

.ColapsableSection {
  background-color: #2c5c9a;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 5px 15px;
}

.Toggle {
  width: 25px;
  height: 25px;
  border-radius: 13px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: #ffffff;
  transition: transform 0.3s ease-out;
}

.ToggleRotate {
  width: 25px;
  height: 25px;
  border-radius: 13px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: #ffffff;
  transition: transform 0.3s ease-out;
  transform: rotate(180deg);
}
.Title {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  margin: 0 15px;
}

.ColapseBox {
  width: 100%;
}

.ColapseBoxWithBorder {
  width: 100%;
  border: 2px solid #2c5c9a;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
