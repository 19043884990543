/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.fc-event {
  font-size: 1.3em;
  font-style: italic;
  font-weight: bold;
}

.fc table td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.fc-button {
  border: 1px solid !important;
  background-color: #f5f5f5 !important;
  background-image: linear-gradient(to bottom, #fff, #e6e6e6);
  background-repeat: repeat-x;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25) !important;
  color: #212529 !important;
}

.fc-button:enabled:hover {
  background: #e6e6e6 !important;
}

.fc-state-active {
  background-color: #cccccc !important;
}
