/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

#create-category-btn,
#save-services-btn {
  border-color: #22ac92;
  background-color: #22ac92;
}

#create-category-btn:hover:enabled,
#save-services-btn:hover:enabled {
  border-color: #22baa0;
  background-color: #22baa0;
}
