.Container {
  padding: 32px;
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.LabelContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  min-height: 40px;
  width: 45%;
}

.FirstSegmentCollapsedContainer {
  padding: 64px 81px 81px 81px;
}
.ColumnContainer {
  display: flex;
  justify-content: space-between;
}

.Column {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 45%;
}

.LabelName {
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  color: #7e838c;
  text-align: left;
}

.LabelValue {
  font-family: 'Montserrat-Medium';
  font-size: 16px;
  text-align: left;
}

.Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.SecondSegmentCollapsedContainer {
  padding-top: 32px;
  padding-left: 46px;
  padding-right: 46px;
  padding-bottom: 46px;
  overflow-wrap: break-word;
}

.SegmentTextStyle {
  font-family: 'Montserrat-Medium';
  font-size: 14px;
  overflow-wrap: break-word;
}
