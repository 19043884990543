/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.avatar-container {
  position: relative;
  text-align: center;
  color: white;
}

.avatar-container img {
  max-width: 210px;
  max-height: 210px;
  border-radius: 50%;
}

.avatar-smaller-container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.avatar-wrapper-class label {
  font-family: 'Montserrat-SemiBold' !important;
  font-size: 16px !important;
}

.avatar-default-tip {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-weight: bold;
  max-width: 200px;
}

.avatar-custom-tip {
  position: absolute;
  bottom: 0;
  left: -webkit-calc(100% - 3em) !important;
  left: calc(100% - 3em) !important;
  cursor: pointer;
  color: #9b9b9b;
}
