/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.p-paginator a {
  border: none;
}

.p-paginator a span {
  color: #666 !important;
}

.p-paginator .p-paginator-page.ui-state-active {
  background-color: transparent !important;
  color: #666 !important;
}

.p-paginator-page {
  font-family: 'Montserrat-Regular' !important;
}

.p-paginator {
  border: none !important;
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: flex-end !important;
  margin-top: 50px !important;
  font-family: 'Montserrat-Regular' !important;
  color: #333333 !important;
}

.p-paginator-pages {
  border: 1px solid #c9c9c9;
  line-height: 1;
}

.p-paginator-element {
  padding-right: 1px !important;
  padding-left: 1px !important;
}

.p-paginator-left-content {
  margin-right: 10px !important;
}

.p-paginator .p-dropdown {
  margin-left: 0 !important;
  border-radius: 0 !important;
  border-color: #c9c9c9 !important;
  padding-left: 5px !important;
  background-color: #ffffff !important;
}

.p-paginator-left-content .p-dropdown-label {
  font-family: 'Montserrat-Regular' !important;
  color: #333333 !important;
  line-height: 1 !important;
  text-align: center !important;
}

.p-paginator .p-dropdown-trigger {
  border-left: none;
}

.p-paginator .p-dropdown-trigger span::before {
  color: #666666;
}
