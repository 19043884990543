.sign-up-container {
  width: 700px;
  margin: 50px auto 50px auto;
}

.sign-up-container button {
  background-color: #22baa0;
  border-color: #22baa0;
}

.sign-up-container button:hover:enabled {
  background-color: #22bab0;
  border-color: #22bab0;
}

.sign-up-container .form-control {
  width: 100%;
  padding: 0;
}

.sign-up-container #error-msg {
  width: 100%;
}

.sign-up-container .error-msg span {
  font-size: small;
}

.sign-up-container form {
  margin: 0 10%;
}

.sign-up-container .terms-text {
  width: 100%;
}

.sign-up-container .captcha-container .row {
  display: block !important;
}

.sign-up-container .p-dropdown,
.sign-up-container .p-dropdown-panel .p-dropdown-filter-container {
  width: 100% !important;
}

.accept-terms-container {
  display: flex;
  flex-direction: row;
}

.terms-text {
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  line-height: 1;
}

@media (max-width: 768px) {
  .sign-up-container {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .sign-up-container form {
    margin: 0 5%;
  }
}

@media (max-width: 350px) {
  .sign-up-container .captcha-container {
    transform: scale(0.83);
    transform-origin: 0 0;
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media (max-width: 300px) {
  .sign-up-container form {
    margin: 0 2%;
  }
}
