/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.ImageContainer {
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.ImageList {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.ImageTitle {
  font-family: 'Montserrat-SemiBold';
  margin-top: 9px;
  color: #2d4374;
  font-weight: 600;
  font-size: 18px;
  max-width: 135px;
  text-align: center;
}

.StaticContent {
  padding-left: 96px;
  padding-right: 96px;
}

.StyledTextContent {
  font-family: 'Montserrat-Medium';
  font-size: 14px;
  margin-top: 45px;
  margin-bottom: 45px;
}

.TermsAcceptedText {
  margin-top: 68px;
  font-family: "Montserrat-Medium";
  font-size: 16px;
  display: flex;
  align-items: center;
}
