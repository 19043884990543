/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.rating-info-row {
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  font-family: 'Montserrat-Regular';
}

.rating-info-row.positive {
  background-color: #d3ecd9;
  border-color: #d3ecd9;
}

.rating-info-row.neutral {
  background-color: #e1e2e4;
  border-color: #e1e2e4;
}

.rating-info-row.negative {
  background-color: #f7d6d9;
  border-color: #f7d6d9;
}
