/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.valuation-inquiry-response-content {
  margin: 0;
}

.valuation-inquiry-response-form-wrapper {
  width: 980px;
  margin-left: auto;
  margin-right: auto;
}

.valuation-inquiry-response-steps-mobile {
  display: none;
}

@media (max-width: 1400px) {
  .valuation-inquiry-response-form-wrapper {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .valuation-inquiry-response-steps {
    display: none;
  }

  .valuation-inquiry-response-steps-mobile {
    display: inherit;
    padding-left: 45%;
  }
}
