.TilesContainer {
    margin-left: 2rem;
    margin-right: 1rem;
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 576px) {
    .TilesContainer {
        margin-right: 2rem;
    }
  }