.distributor-profile .card {
  border: none;
  background-color: transparent !important;
}

.distributor-profile h3 {
  font-family: 'Montserrat-Medium';
  color: #2A3FAB;
}

.distributor-profile textarea {
  resize: none;
}

.distributor-profile-card {
  border-top: 1px solid #c6c6c6;
  background-color: transparent;
}

@media (max-width: 400px) {
  .distributor-profile .distributor-details-form .phone-number-input {
    width: calc(100% - 3em) !important;
  }
}
