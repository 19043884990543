/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.services-table thead tr th {
  border-bottom: none !important;
}

.services-table .p-datatable-even,
.services-table .p-datatable-odd,
.services-table .p-datatable-even td:nth-child(1),
.services-table .p-datatable-odd td:nth-child(1) {
  background-color: transparent !important;
}

.services-table .p-datatable-even:hover,
.services-table .p-datatable-odd:hover,
.services-table .p-datatable-even:hover td:nth-child(1),
.services-table .p-datatable-odd:hover td:nth-child(1) {
  background-color: #f0f0f0 !important;
}

.services-table table tbody tr:last-child {
  border-bottom: none !important;
}

#edit-service-btn {
  margin-right: 10px;
  float: right;
}

.service-button-section {
  display: flex;
  flex-direction: column;
  text-align: center;
}
