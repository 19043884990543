.side-bar-container {
  float: left;
  position: fixed;
  height: 100% !important;
  width: 260px;
  padding-top: 60px;
  z-index: 3;
}

.side-bar-nav {
  height: 100%;
  padding-top: 30px;
  background-color: #fcfcfc;
  overflow-x: hidden;
  text-align: center;
  -webkit-box-shadow: inset -4px 0px 4px -3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset -4px 0px 4px -3px rgba(0, 0, 0, 0.15);
  box-shadow: inset -4px 0px 4px -3px rgba(0, 0, 0, 0.15);
}

.side-bar-nav ul li a p span {
  font-family: 'Montserrat-Bold';
}

@media (max-width: 768px) {
  .side-bar-container {
    display: none;
  }
}
