/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.user-activation-info-container {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
}

.user-activation-info-content {
  padding: 2rem 4rem 2rem 4rem;
  text-align: center;
}

.user-activation-info-text {
  font-family: 'Montserrat-Regular';
  font-size: 14px;
  margin-bottom: 2rem;
}
