/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.valuation-inquiry-response-form .p-dropdown,
.valuation-inquiry-response-form .p-dropdown-filter-container {
  width: 100% !important;
}

.valuation-inquiry-response-form .p-dropdown.small {
  width: 25% !important;
}

.react-datepicker__input-container input {
  max-width: inherit;
}
.p-dropdown {
  width: 100%;
}

.ui-inputgroup {
  display: flex !important;
  justify-content: flex-start;
}
