.Container {
}

.Line {
  margin-bottom: 10px;
}

.Link {
  display: block;
  margin-bottom: 10px;
  color: #495057;
}

.Header {
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
}
