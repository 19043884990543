.react-datepicker-wrapper {
  max-width: 100% !important;
}

.react-datepicker__input-container {
  max-width: inherit !important;
}

.react-datepicker__input-container input {
  max-width: inherit;
}

.clear-icon {
  color: #888888;
  position: relative;
  font-size: 0.75rem !important;
}

.clear-icon:hover {
  cursor: pointer;
}

.p-inputgroup-addon {
  background-color: inherit !important;
  border-top: none !important;
  border-right: none !important;
}

.p-inputgroup-addon.disabled.clickable {
  cursor: default;
}

.p-inputgroup-addon.disabled.clickable .clear-icon:hover{
  cursor: default;
}