.WorkshopLoyaltyButtons {
    display: flex;
    justify-content: center;
  }

.WorkshopLoyaltyFlexPointsAccrualPeriod {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.Header{
  margin-bottom: 1rem; 
  font-weight: bold; 
}
  