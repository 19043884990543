.Container {
  padding: 32px 240px 32px 240px;
}

@media (max-width: 1500px) {
  .Container {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.NextBtnWrapper {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.Switch {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  border: solid 2px #c6c6c6;
  margin-left: 70px;
  margin-bottom: 22px;
}

.SwitchChecked {
  border: solid 2px #2A3FAB;
  background-color: #2A3FAB;
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-left: 70px;
   margin-bottom: 22px;
}

.Switch:last-of-type .SwitchChecked:last-of-type {
  margin-right: 32px;
}

.Unit {
  position: absolute;
  display: flex;
  top: -8px;
  right: -35px;
}

.BottomSection {
  width: 100%;
  display: flex;
  align-items: flex-end;
}
