/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.workshop-profile-buttons {
  text-align: right;
}

.workshop-details-section {
  border-top: 1px solid #d4d4db;
  margin-bottom: 20px;
}

.workshop-details-section-title {
  font-family: 'Montserrat-Medium' !important;
  font-size: 20px !important;
  text-align: center !important;
  margin: 20px 0 20px 0;
}

.edit-workshop-form #search-box {
  margin-top: 10px;
  position: relative;
  background: #fff;
  border-radius: 2px;
  height: 40px;
  width: 50%;
  border: 1px solid transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
  transition-duration: 0.3s;
}
