.Container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Label {
  width: 40%;
  font-family: "Montserrat-Medium";
  font-size: 14px;
}

.Dot {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  border: solid 2px #c6c6c6;
  margin-right: 20px;
  
}

.DotChecked {
  border: solid 2px #2A3FAB;
  background-color: #2A3FAB;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 20px;
}

.DotBox {
  width: 80px;
  display: flex;
  align-items: center;
}

.DotLabel {
  margin: 0;
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
}

.DotsBox {
  display: flex;
}
