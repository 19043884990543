.Container {
  overflow-x: auto;
  padding-bottom: 32px;
  padding-right: 32px;
}

.Header {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.GlobalFilter {
  width: 20%;
  min-width: 150px;
}
.FilterContainer {
  min-width: 150px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.Check {
  border: 2px solid #b3b3b3 !important;
  margin: 0 5px 4px 10px !important;
  position: static !important;
}

.StateLabel {
  position: relative;
  margin-right: 7px;
}

.StateLabel::after {
  content: '';
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: #CC0000;
  display: inline-block;
  top: 2px;
  right: -5px;
}

.DotContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.RedDot {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #CC0000;
}
.GreenDot {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #458a43;
}

.ClicableField {
  cursor: pointer;
}
