/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.inquiry-summary-card {
  border-color: #CC0000 !important;
  height: 240px !important;
}

.inquiry-summary-card-header {
  text-align: center;
  background-color: #CC0000 !important;
  color: #ffffff;
  font-size: 24px;
  font-family: 'Montserrat-SemiBold';
  line-height: 1;
}

.inquiry-summary-card-body {
  overflow-y: auto !important;
  font-family: 'Montserrat-Regular';
  font-size: 16px;
}
