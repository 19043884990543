@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('./Lato/Lato-Regular.eot'),
    /* IE9 Compat Modes */ url('./Lato/Lato-Regular.woff') format('woff'),
    /* Modern Browsers */ url('./Lato/Lato-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./Montserrat/Montserrat-Regular.eot');
  src: url('./Montserrat/Montserrat-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./Montserrat/Montserrat-Regular.woff2') format('woff2'),
    url('./Montserrat/Montserrat-Regular.woff') format('woff'),
    url('./Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('./Montserrat/Montserrat-SemiBold.eot');
  src: url('./Montserrat/Montserrat-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('./Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
    url('./Montserrat/Montserrat-SemiBold.woff') format('woff'),
    url('./Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./Montserrat/Montserrat-Bold.eot');
  src: url('./Montserrat/Montserrat-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('./Montserrat/Montserrat-Bold.woff2') format('woff2'),
    url('./Montserrat/Montserrat-Bold.woff') format('woff'),
    url('./Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('./Montserrat/Montserrat-Medium.eot');
  src: url('./Montserrat/Montserrat-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('./Montserrat/Montserrat-Medium.woff2') format('woff2'),
    url('./Montserrat/Montserrat-Medium.woff') format('woff'),
    url('./Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('./Montserrat/Montserrat-ExtraBold.eot');
  src: url('./Montserrat/Montserrat-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('./Montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
    url('./Montserrat/Montserrat-ExtraBold.woff') format('woff'),
    url('./Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url('./Montserrat/Montserrat-Light.eot');
  src: url('./Montserrat/Montserrat-Light.eot?#iefix')
      format('embedded-opentype'),
    url('./Montserrat/Montserrat-Light.woff2') format('woff2'),
    url('./Montserrat/Montserrat-Light.woff') format('woff'),
    url('./Montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ArialMT';
  src: url('./ArialMT/ArialMT.eot');
  src: url('./ArialMT/ArialMT.eot?#iefix') format('embedded-opentype'),
    url('./ArialMT/ArialMT.woff2') format('woff2'),
    url('./ArialMT/ArialMT.woff') format('woff'),
    url('./ArialMT/ArialMT.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
