.Container {
  padding: 32px 82px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.Text {
  width: 100%;
  text-align: left;
}
