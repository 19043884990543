.phone-number-wrapper {
  text-align: left !important;
}

.phone-number-wrapper .phone-prefix {
  min-width: 4em !important;
  border-bottom: 1px solid #d6d6d6;
  text-align: center;
}

.phone-number-wrapper .phone-prefix:hover {
  border-bottom: 1px solid #2A3FAB;
}

.phone-number-wrapper .ui-inputgroup-addon {
  min-width: 3em !important;
}

.phone-number-wrapper .dropdown {
  margin-top: 4px;
}

.phone-number-wrapper .dropdown .clickable {
  font-family: 'Montserrat-Regular';
  line-height: 24px;
  color: #000000;
}

.phone-number-wrapper .dropdown .clickable i {
  color: #666666;
}

.phone-number-input {
  width: 100%;
}

@media (max-width: 576px) {
  .phone-number-input {
    width: calc(100% - 4em);
  }
}
