/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.valuation-inquiry-container {
  margin: 2rem 2rem 4rem 2rem;
}

.public-valuation-inquiry-container {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.valuation-inquiry-content {
  padding: 2rem 15rem 2rem 15rem;
  box-shadow: none !important;
}

.public-valuation-inquiry-content {
  padding: 3rem;
}

.valuation-inquiry-steps-mobile {
  display: none;
}

.public-valuation-container {
  margin: auto;
  width: 98%;
  padding-top: calc(60px + 1%);
  padding-top: -webkit-calc(60px + 1%);
}

@media (max-width: 1500px) {
  .valuation-inquiry-content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 992px) {
  .public-valuation-container {
    width: 96%;
    padding-top: calc(60px + 2%);
    padding-top: -webkit-calc(60px + 2%);
  }
}

@media (max-width: 768px) {
  .public-valuation-inquiry-container {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .valuation-inquiry-steps {
    display: none;
  }

  .valuation-inquiry-steps-mobile {
    display: inherit;
    padding-left: 45%;
  }

  .public-valuation-container {
    width: 94%;
    padding-top: calc(60px + 3%);
    padding-top: -webkit-calc(60px + 1%);
  }
}
