/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.create-order-vendor-rating-container {
  margin: 50px auto auto auto;
  width: 45%;
}

.create-order-vendor-rating-container .card-header {
  text-align: center;
  font-weight: bold;
  font-size: large;
  color: white;
  background-color: #22baa0;
}

@media (max-width: 1511px) {
  .create-order-vendor-rating-container {
    width: 50%;
  }
}

@media (max-width: 1320px) {
  .create-order-vendor-rating-container {
    width: 55%;
  }
}

@media (max-width: 1200px) {
  .create-order-vendor-rating-container {
    width: 65%;
  }
}

@media (max-width: 768px) {
  .create-order-vendor-rating-container {
    margin: 0;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .create-order-vendor-rating-container .card-body {
    padding: 0.75rem;
  }
}

@media (max-width: 300px) {
  .create-order-vendor-rating-container .card-body {
    padding: 0.5rem;
  }
}
