/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
.Container {
  margin: 32px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
}

@media (max-width: 1195px) {
  .Container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
}

@media (max-width: 580px) {
  .Container {
    grid-template-columns: 1fr;
  }
}

.BtnTab {
  cursor: pointer;
  background-color: #2A3FAB;
  min-width: 200px;
  width: 100%;
  height: 88px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BtnDisabledTab {
  cursor: pointer;
  background-color: #495057;
  min-width: 200px;
  width: 100%;
  height: 88px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BtnTabActive {
  cursor: pointer;
  background-color: #1a2d56;
  min-width: 200px;
  width: 100%;
  height: 88px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TabTxt {
  color: #ffffff;
  font-family: 'Montserrat-Regular';
  font-weight: bold;
  font-size: 12px;
  margin-top: 18px;
  text-align: center;
}

.Line {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #CC0000;
}

.WarrantyButtons {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 44px;
  padding-right: 44px;
}
