/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.rc-steps {
  margin-top: 20px;
}

.rc-steps-item-icon {
  padding-top: 2px;
  font-family: 'ArialMT';
}

.rc-steps-item-icon span {
  font-size: 18px !important;
  font-weight: 800 !important;
}

.rc-steps-item.rc-steps-item-finish .rc-steps-item-tail::after {
  background-color: #2A3FAB !important;
}

.rc-steps-item.rc-steps-item-finish .rc-steps-item-icon {
  background-color: #2A3FAB !important;
  border-color: #2A3FAB !important;
}

.rc-steps-item-icon .rcicon-check::before {
  margin-top: 3px;
  color: white !important;
  content: '\f00c' !important;
  font-family: 'FontAwesome' !important;
}

.rc-steps-item.rc-steps-item-finish .rc-steps-item-icon .rc-steps-icon {
  color: #2A3FAB !important;
}

.rc-steps-item.rc-steps-item-process .rc-steps-item-icon {
  background-color: #2A3FAB !important;
  border-color: #2A3FAB !important;
}
