/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

#add-statute-btn .ui-fileupload-choose .ui-button-icon-left {
  display: none;
}

#add-statute-btn .ui-fileupload-choose {
  font-family: 'Montserrat-Medium' !important;
  color: #ffffff !important;
  font-size: 15px !important;
  min-width: 90px !important;
  padding: 5px 10px 5px 10px !important;
  margin: 5px !important;
  border-color: #2A3FAB !important;
  background-color: #2A3FAB !important;
}

.choose-country .p-dropdown-label {
  padding-right: 2rem !important;
}

.p-button {
  background:#2A3FAB !important;
  border-color: #2A3FAB !important;
}

.p-button-label{
  font-family: 'Montserrat-Medium' !important;
  color: #ffffff !important;
}