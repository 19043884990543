/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.containers {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
  margin-top: 0rem !important;
}
