/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.modal-header {
  background-color: #fcfcfc !important;
  border-bottom: 1px solid #eee !important;
  padding-left: 3rem !important;
}

.modal-title {
  font-family: 'Montserrat-ExtraBold';
  font-size: 24px;
  color: #242422;
  text-align: center;
  width: 100%;
}

.modal-body {
  margin-top: 1rem !important;
}

.modal-footer {
  border-top: none !important;
  padding-top: 0 !important;
}

.modal-header .close {
  opacity: 1 !important;
}

.modal-header .close span {
  display: none;
}

.modal-header .close:after {
  font-family: 'FontAwesome';
  font-weight: 400;
  content: '\f00d';
  color: #333333;
  line-height: 1.5;
}

.modal-header .close:hover {
  opacity: 0.75 !important;
}
