/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.react-datepicker__time-list-item.high-workload,
.react-datepicker__day.high-workload {
  color: red;
}

.react-datepicker__time-list-item.medium-workload,
.react-datepicker__day.medium-workload {
  color: #ffac04;
}

.react-datepicker__time-list-item.low-workload,
.react-datepicker__day.low-workload {
  color: #0e5510;
}
