.selected-workshop-wrapper .buttons button {
  width: 100%;
  border-color: #22ac92;
  background-color: #22ac92;
}

.selected-workshop-wrapper .buttons button:hover:enabled {
  border-color: #22baa0;
  background-color: #22baa0;
}

.selected-workshop-wrapper .buttons {
  padding: 0 10%;
}

.workshop-form h3 {
  color: #2A3FAB;
  font-family: 'Montserrat-SemiBold';
}
