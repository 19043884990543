.termsAccepted .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #46b25b;
  background: #46b25b;
}

.termsAccepted .p-checkbox .p-checkbox-box.p-highlight.p-disabled {
  opacity: 1;
  border-color: #2A3FAB !important;
  background: #2A3FAB !important;
}

.termsAccepted {
  margin-top: 68px;
  font-family: "Montserrat-Medium";
  font-size: 16px;
  display: flex;
  align-items: center;
}
