/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.user-type-tile {
  background-color: white;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  text-align: center;
}

.active {
  background-color: #22baa0;
  color: white;
}

@media (min-width: 1025px) {
  .user-type-tile:hover {
    background-color: #22baa0;
    color: white;
  }
}

.selected-user-profile-data button,
.add-admin-form button {
  border-color: #22ac92;
  background-color: #22ac92;
}

.selected-user-profile-data button:hover:enabled,
.add-admin-form button:hover:enabled {
  border-color: #22baa0;
  background-color: #22baa0;
}
