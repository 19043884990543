/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.user-notifications {
  text-align: right;
}

.user-notifications ul {
  margin-top: 24px;
  line-height: 30px !important;
  width: 400px;
  float: right;
  list-style-type: none;
  display: inline-block;
  font-size: large;
  text-align: left;
  padding-left: 0;
  padding-right: 10px;
  border: 2px solid #9e9e9d;
  border-radius: 0.75rem;
  background-color: white;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.user-notifications li {
  border-bottom: 1px solid #cfcfce;
  margin-left: 10px;
}

#user-notifications-toggle i {
  display: inline;
  vertical-align: middle;
  color: #2A3FAA;
  font-size: 24px;
}

#user-notifications-toggle i:hover {
  color: #b2b2b2;
}

#user-notifications-toggle i.active {
  background-color: #fcfcfc;
  color: #b2b2b2;
}

.user-notifications-collapse.collapse.show,
.user-notifications-collapse.collapsing {
  position: absolute;
  right: 15px;
}

.user-notifications-delete {
  text-align: center;
  cursor: pointer;
}

.user-notifications-delete:hover {
  background-color: #ebebeb;
}

.user-notification {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 80px;
}

.user-notification-icon-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 10px;
}

.user-notification-icon {
  color: #294174;
  text-align: center;
}

.user-notification-content-container {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.user-notification-content {
  color: #4d4d4d;
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  word-wrap: break-word;
  line-height: 1;
}

.user-notification-content-row {
  margin-bottom: 30px;
}

.user-notification-date {
  font-size: 10px;
  font-family: 'Montserrat-Regular';
  text-align: right;
  position: absolute !important;
  bottom: 0;
  right: 0;
}

@media (max-width: 500px) {
  .user-notifications ul {
    width: 300px !important;
    margin-right: -80px;
  }
}
