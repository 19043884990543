.WorkshopLoyaltyFlexPointsAccrualPeriod {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .Header{
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  .LabelContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    min-height: 40px;
    width: 100%;
    word-wrap: normal;
  }

  .LabelName {
    font-family: 'Montserrat-Regular';
    font-size: 12px;
    color: #7e838c;
    text-align: left;
    font-weight: bold;
  }

  .LabelValue {
    font-family: 'Montserrat-Medium';
    font-size: 16px;
    text-align: left;
  }

  .LabelValueWithSpace {
    font-family: 'Montserrat-Medium';
    font-size: 16px;
    text-align: left;
    margin-left: 5px;
  }

  .RenderPointsAccuralPeriod {
    display: flex;
    justify-content: center;
    font-weight: bold;
    padding-bottom: 2rem;
    margin-top: 2rem;
  }

  .ButtonSection {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem
  }

  .InputField {
    width: 340px;
  }

  @media (max-width: 510px) {
    .InputField {
      width: 100%;
    }
  }
  