/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

#update-data-sidebar {
  position: absolute;
  border-radius: 10px;
  width: auto;
  height: auto;
  top: 12rem;
  left: calc(10% + 260px); /* 10% +  left sidebar navigation width */
  right: 10%;
  padding: 0 !important;
}

#update-data-sidebar .top-bar {
  min-height: 50px;
  width: 100%;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  background-color: #fcfcfc;
  color: #242422;
  font-family: 'Montserrat-ExtraBold';
  font-size: 24px;
  text-align: center;
  padding-top: 8px;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: none;
}

#update-data-sidebar .ui-sidebar-close:hover {
  color: red !important;
  background-color: transparent !important;
}

#update-data-sidebar .ui-sidebar-close {
  vertical-align: middle;
  font-size: 24px;
  color: #242422;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 5px;
}

.p-row-toggler {
  background-color: #2A3FAB !important;
}
.p-row-toggler:focus {
  box-shadow: none !important;
}

.p-row-toggler {
  color: white !important;
}

tr {
  outline-color: white !important;
}

.p-sidebar-icons {
  background-color: #fcfcfc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.p-sidebar-close {
  color: #242422 !important;
  margin-right: 10px !important;
}
.p-sidebar-close:hover {
  background-color: inherit !important;
  color: grey !important;
}

.p-sidebar-close:focus {
  box-shadow: none !important;
}

@media (max-width: 380px) {
  #update-data-sidebar {
    left: 2%;
    right: 2%;
  }
} 

@media (min-width: 381px) {
  #update-data-sidebar {
    left: 10%;
  }
}

@media (min-width: 1369px) {
  #update-data-sidebar {
    left: calc(14% + 260px); 
    right: 14%;
  }
}

@media (min-width: 1680px) {
  #update-data-sidebar {
    left: calc(17% + 260px); 
    right: 17%;
  }
}

@media (min-width: 1920px) {
  #update-data-sidebar {
    left: calc(20% + 260px); 
    right: 20%;
  }
}

