.user-collapse #span-toggle {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 8px;
  justify-items: center;
  align-items: center;
  height: 60px;
  padding: 16px 32px;
}


.user-collapse #span-toggle i {
  color: #2A3FAA !important;
  font-size: 24px;
}

.user-collapse-ul {
  border: 2px solid #cfcfce;
}

.user-collapse-ul i {
  font-size: 23px;
  vertical-align: middle;
  width: 20px;
  text-align: center;
}

.user-collapse span {
  color: #4d4d4d;
  cursor: pointer;
  overflow: hidden;
  font-family: 'Montserrat-SemiBold';
  font-size: 12px;
  line-height: 23px;
}

.user-collapse ul {
  line-height: 30px !important;
  width: 100%;
  float: right;
  list-style-type: none;
  display: block;
  text-align: left;
}

.username {
  font-family: 'Montserrat-SemiBold';
  font-size: 12px;
  color: #2A3FAA !important;
}

.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

@media (min-width: 769px) {

  .user-collapse #span-toggle {
    height: auto;
    padding: 0;
  }

  .user-collapse {
    text-align: center;
  }

  .user-collapse ul {
    width: 200px;
    padding-left: 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    list-style-type: none;
    background-color: white;
    position: absolute;
    right: 10px;
  }

  #span-toggle i {
    display: inline-block;
  }

  .user-collapse ul > li {
    color: #5f5f5f;
  }

  .user-collapse .collapsing {
    position: absolute;
    width: 210px;
    right: 0;
  }
}

@media (max-width: 768px) {
  .user-collapse ul {
    min-width: 100%;
    text-align: center;
    border: none;
    box-shadow: none;
    margin-left: 30px;
  }

  .user-collapse-ul > li {
    font-size: smaller;
  }

  .user-collapse-ul a {
    display: block;
    padding: 0 !important;
  }

  .user-collapse-ul a:hover {
    background-color: inherit;
  }

  #span-toggle {
    color: #899dc1;
  }

  #span-toggle i {
    display: block;
  }

  .user-collapse-ul li:hover span,
  .user-collapse-ul li:hover i {
    color: #2A3FAA;
  }

  .user-collapse-active span,
  .user-collapse-active i {
    color: #2A3FAA !important;
  }

  .user-collapse-ul li span,
  .user-collapse-ul li i {
    color: #899dc1;
  }

  .username {
    display: none;
  }

  .user-avatar {
    width: 100px;
    height: 100px;
  }
}

.clickable {
  cursor: pointer;
}
