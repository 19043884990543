.Container {
  padding: 32px;
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FirstPart {
  width: 50%;
}

.SecondPart {
  min-width: 800px;
}

.ThirdPart {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  margin-bottom: 45px;
}

@media (max-width: 1170px) {
  .ThirdPart {
    width: 100%;
    grid-template-columns: 1fr;
    column-gap: 0;
  }

  .FirstPart {
    width: 100%;
  }
}

.LabelContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  min-height: 40px;
}

.LabelName {
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  color: #7e838c;
  text-align: left;
}

.LabelValue {
  font-family: 'Montserrat-Medium';
  font-size: 16px;
  text-align: left;
}

.SegmentCollapsedContainer {
  padding: 32px 81px;
  overflow-x: auto;
}

.Row {
  width: 100%;
  justify-self: flex-start;
  font-family: 'Montserrat-Medium';
  font-size: 14px;
}

.Label {
  width: 100%;
  min-height: 94px;
  display: grid;
  grid-template-columns: 3fr 1fr 2.5fr;
  justify-content: center;
}

.Yes {
  color: #458a43;
  margin: 0;
  width: 30px;
  justify-self: center;
  font-family: 'Montserrat-Bold' !important;
  font-size: 14px;
}

.Not {
  color: #CC0000;
  margin: 0;
  width: 30px;
  justify-self: center;
  font-family: 'Montserrat-Bold' !important;
  font-size: 14px;
}

.Description {
  width: 400px;
  word-wrap: break-word;
  margin-bottom: 0;
  justify-self: flex-end;
  font-family: 'Montserrat-Medium';
  font-size: 14px;
}
