@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Lato-Regular.19df47b6.eot),
     url(/static/media/Lato-Regular.1d2ca94d.woff) format('woff'),
     url(/static/media/Lato-Regular.bb14dc80.ttf) format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url(/static/media/Montserrat-Regular.3220cdd4.eot);
  src: url(/static/media/Montserrat-Regular.3220cdd4.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/Montserrat-Regular.8b56391e.woff2) format('woff2'),
    url(/static/media/Montserrat-Regular.3db65dc4.woff) format('woff'),
    url(/static/media/Montserrat-Regular.317b418e.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url(/static/media/Montserrat-SemiBold.262fb106.eot);
  src: url(/static/media/Montserrat-SemiBold.262fb106.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/Montserrat-SemiBold.1d679c82.woff2) format('woff2'),
    url(/static/media/Montserrat-SemiBold.19721359.woff) format('woff'),
    url(/static/media/Montserrat-SemiBold.87718949.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url(/static/media/Montserrat-Bold.4b33f3d3.eot);
  src: url(/static/media/Montserrat-Bold.4b33f3d3.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/Montserrat-Bold.cfceaf4e.woff2) format('woff2'),
    url(/static/media/Montserrat-Bold.180ba33d.woff) format('woff'),
    url(/static/media/Montserrat-Bold.5885feb2.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url(/static/media/Montserrat-Medium.418e43db.eot);
  src: url(/static/media/Montserrat-Medium.418e43db.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/Montserrat-Medium.d6bbb7c3.woff2) format('woff2'),
    url(/static/media/Montserrat-Medium.d42dad28.woff) format('woff'),
    url(/static/media/Montserrat-Medium.693c527a.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url(/static/media/Montserrat-ExtraBold.3e1f7a55.eot);
  src: url(/static/media/Montserrat-ExtraBold.3e1f7a55.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/Montserrat-ExtraBold.724e2fc5.woff2) format('woff2'),
    url(/static/media/Montserrat-ExtraBold.75ee4192.woff) format('woff'),
    url(/static/media/Montserrat-ExtraBold.0697040c.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url(/static/media/Montserrat-Light.d66eb8dd.eot);
  src: url(/static/media/Montserrat-Light.d66eb8dd.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/Montserrat-Light.cf7b6a95.woff2) format('woff2'),
    url(/static/media/Montserrat-Light.be142e6c.woff) format('woff'),
    url(/static/media/Montserrat-Light.c3468d41.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ArialMT';
  src: url(/static/media/ArialMT.9e06425e.eot);
  src: url(/static/media/ArialMT.9e06425e.eot?#iefix) format('embedded-opentype'),
    url(/static/media/ArialMT.c2c849c8.woff2) format('woff2'),
    url(/static/media/ArialMT.b68ba0c4.woff) format('woff'),
    url(/static/media/ArialMT.3a08817d.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

.pac-container {
  z-index: 1000000;
}

body {
  font-family: 'Lato' !important;
  background-color: #eeeeee!important;
  margin: 0;
  padding: 0;
}

.p-component-overlay.p-sidebar-mask {
 z-index: 2 !important;
}

.p-growl {
  top: 65px !important;
}

.p-growl-message-success {
  background-color: #22baa0 !important;
}

@media (max-width: 576px) {
  .ui-growl {
    width: 70% !important;
  }
}

.root-panel {
  background-color: white;
}

.flex {
  display: flex !important;
}

.error-color {
  color: #CC0000 !important;
}

.error-info {
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  color: #CC0000;
}

.shadow {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
}

.bottom-shadow {
  /*
  -webkit-box-shadow: 0 0 4px 0 #bbbbbb;
  -moz-box-shadow: 0 0 4px 0 #bbbbbb;
  box-shadow: 0 0 4px 0 #bbbbbb;
  */
}

.field-label {
  font-family: 'Montserrat-Regular' !important;
  font-size: 12px !important;
  color: #7e838c;
}

.read-only-label {
  font-family: 'Montserrat-SemiBold' !important;
  font-size: 16px !important;
}

input[type='checkbox'] {
  outline: none;
}

.checkbox-label {
  font-family: 'Montserrat-Regular' !important;
  font-size: 10px !important;
  margin-left: 10px;
}

.checkbox-label a {
  color: #000000 !important;
}

.vertical-centered {
  display: flex;
  align-items: center;
}

.back-btn {
  cursor: pointer;
  color: gray;
}

.back-btn:hover {
  color: #22baa0;
}

.back-btn-col {
  display: flex;
  align-items: center;
}

@media (max-width: 576px) {
  .back-btn-col {
    justify-content: center;
  }
}

.main-btn {
  font-family: 'Montserrat-Bold' !important;
  color: #ffffff !important;
  font-size: 15px !important;
  min-width: 90px;
  padding: 5px 10px 5px 10px !important;
  margin: 5px !important;
  border-color: #2A3FAB !important;
  background-color: #2A3FAB !important;
}

.main-btn:hover:enabled {
  border-color: #203157 !important;
  background-color: #203157 !important;
}

.main-btn:disabled {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.btn-danger {
  font-family: 'Montserrat-Medium' !important;
  color: #ffffff !important;
  font-size: 15px !important;
  min-width: 90px !important;
  padding: 5px 10px 5px 10px !important;
  margin: 5px !important;
  background-color: #CC0000 !important;
  border-color: #CC0000 !important;
}

.btn-danger:hover {
  background-color: #ac221b !important;
  border-color: #ac221b !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.radio-button .ui-radiobutton-box {
  border-color: #b5b5b5 !important;
  background-color: #ffffff !important;
}

.radio-button .ui-radiobutton-icon {
  color: #2A3FAB !important;
}

.radio-button-label {
  margin-left: 0.5rem;
  font-family: 'Montserrat-Regular';
  font-size: 16px;
  color: #000000;
}

.type-tile {
  background-color: white;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  text-align: center;
}

@media (min-width: 1025px) {
  .type-tile:hover {
    background-color: #22baa0;
    color: white;
  }
}

.active-type-tile {
  background-color: #22baa0;
  color: white;
}

.font-bold {
  font-size: 1rem;
  font-weight: bold;
}

.ui-widget-overlay {
  z-index: 1000 !important;
}

.pi {
  font-weight: bold !important;
}

#update-data-sidebar {
  z-index: 1001 !important;
}

.modal-backdrop {
  z-index: 1003 !important;
}

.modal {
  z-index: 1004 !important;
}

.modal-content {
  z-index: 1005 !important;
}

.fixed-width-btn {
  width: 7rem;
}

.fixed-width-big-btn {
  width: 10rem;
}

.fixed-width-xbig-btn {
  width: 14.5rem;
}

.not-clickable {
  pointer-events: none;
}

.version-footer-container {
  width: 100%;
  height: 30px;
  position: fixed;
  bottom: 0;
  padding-right: 20px;
  background-color: #fcfcfc;
  box-shadow: 0 0 4px 0 #bbbbbb;
  z-index: 2;
}

.version-footer-content {
  height: 100%;
  width: auto;
  float: right;
  color: #575756;
  line-height: 30px;
  font-size: 12px;
  font-family: 'Montserrat-Regular';
}

.fixed-input-addon {
  width: 3em;
}

.ui-placeholder {
  color: #788087 !important;
}

.ui-chkbox-box {
  height: 25px !important;
  width: 25px !important;
  border: 2px solid #c0c0c0 !important;
  font-size: 20px !important;
}

.custom-tooltip,
.ui-tooltip-text {
  max-width: none !important;
  background-color: #f4917d !important;
  border: 1px solid #f4917d !important;
  color: white !important;
  font-size: 16px !important;
  font-family: 'Lato' !important;
  text-align: center;
  display: inline-block;
  word-wrap: break-word;
}

.custom-tooltip-smaller-width,
.ui-tooltip-text {
  width: 25vw !important;
}

.tooltip .arrow::before,
.ui-tooltip-arrow {
  border-top-color: #f4917d !important;
}

.break-all {
  word-break: break-all;
}

.hyphens {
  -webkit-hyphens: auto;
  hyphens: auto;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.modal-header {
  background-color: #fcfcfc !important;
  border-bottom: 1px solid #eee !important;
  padding-left: 3rem !important;
}

.modal-title {
  font-family: 'Montserrat-ExtraBold';
  font-size: 24px;
  color: #242422;
  text-align: center;
  width: 100%;
}

.modal-body {
  margin-top: 1rem !important;
}

.modal-footer {
  border-top: none !important;
  padding-top: 0 !important;
}

.modal-header .close {
  opacity: 1 !important;
}

.modal-header .close span {
  display: none;
}

.modal-header .close:after {
  font-family: 'FontAwesome';
  font-weight: 400;
  content: '\f00d';
  color: #333333;
  line-height: 1.5;
}

.modal-header .close:hover {
  opacity: 0.75 !important;
}

.content {
  padding: 60px 0 0 260px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .content {
    padding-left: 0;
  }
}

.nav-header {
  position: fixed;
  width: 100%;
  background-color: #fcfcfc;
  height: 60px;
  z-index: 1000;
  box-shadow: 0 0 4px 0 #bbbbbb;
}

.logo {
  display: block;
  float: left;
  background-color: #fcfcfc;
  height: 50px;
  padding-left: 30px;
  padding-top: 2px;
}

.logo-margin {
  padding-left: 180px;
}

.logo .logo-content {
  line-height: 56px;
  align-items: center;
  font-weight: bold;
  color: white;
}

.nav-header .wrapper {
  float: right;
  margin-top: 10px;
  padding: 0 20px;
  color: #5f5f5f;
}

.nav-header .wrapper a {
  text-decoration: none;
  cursor: pointer;
  color: #5f5f5f;
}

.nav-header .wrapper a:hover {
  color: #5f5f5f;
}

.user-data {
  display: block;
  cursor: pointer;
  padding-right: 20px;
  float: right;
}

.login-logout {
  margin-top: -5px;
  color: #2A3FAA;
  display: block;
  line-height: 1;
  text-align: center;
}

.login-logout i {
  font-size: 24px;
}

.login-label {
  font-family: 'Montserrat-SemiBold';
  font-size: 12px;
}

.bm-menu-wrap {
  width: 260px !important;
}

.bm-burger-button {
  display: none;
  position: fixed;
  width: 36px;
  height: 30px;
  left: 15px;
  top: 15px;
}

.hidden {
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #2A3FAA;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  text-align: center;
  background: #fff;
  padding-top: 1.6em;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

.align-horizontal {
  position: relative;
  top: -2px;
}

@media (max-width: 768px) {
  .menu-toggler,
  .bm-burger-button {
    display: block;
  }

  .user-data {
    display: none;
  }

  .logo-margin {
    padding-left: 40px;
  }
}

@media (max-width: 510px) {
  .logo {
    display: none;
  }
}

.content-wrapper ul {
  padding: 0;
  list-style-type: none;
}

.content-wrapper a {
  padding: 10px 10px 10px 20px;
  text-decoration: none !important;
  outline: 0;
  width: 100% !important;
  color: #2A3FAA !important;
  display: flex;
  border-left: 4px solid transparent;
}

.content-wrapper a::before {
  margin-top: auto;
  margin-bottom: auto;
  min-width: 40px;
}

.content-wrapper a:hover {
  background-color: #2A3FAA;
  color: white !important;
}

.content-wrapper a > p {
  margin-left: 8px;
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  vertical-align: middle;
  margin-top: auto !important;
  margin-bottom: auto !important;
  text-align: left !important;
  line-height: 1.5;
  display: flex;
}

.content-wrapper a > p > i {
  margin-left: 6px;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.content-wrapper .active {
  background-color: #CC0000;
  color: #ffffff !important;
  border-left: 4px solid #CC0000;
}

.content-wrapper .user-collapse {
  display: none;
}

@media (max-width: 768px) {
  #user-avatar {
    display: none;
  }

  .content-wrapper .user-collapse {
    display: block;
  }
}

.user-collapse #span-toggle {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 8px;
  justify-items: center;
  align-items: center;
  height: 60px;
  padding: 16px 32px;
}


.user-collapse #span-toggle i {
  color: #2A3FAA !important;
  font-size: 24px;
}

.user-collapse-ul {
  border: 2px solid #cfcfce;
}

.user-collapse-ul i {
  font-size: 23px;
  vertical-align: middle;
  width: 20px;
  text-align: center;
}

.user-collapse span {
  color: #4d4d4d;
  cursor: pointer;
  overflow: hidden;
  font-family: 'Montserrat-SemiBold';
  font-size: 12px;
  line-height: 23px;
}

.user-collapse ul {
  line-height: 30px !important;
  width: 100%;
  float: right;
  list-style-type: none;
  display: block;
  text-align: left;
}

.username {
  font-family: 'Montserrat-SemiBold';
  font-size: 12px;
  color: #2A3FAA !important;
}

.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

@media (min-width: 769px) {

  .user-collapse #span-toggle {
    height: auto;
    padding: 0;
  }

  .user-collapse {
    text-align: center;
  }

  .user-collapse ul {
    width: 200px;
    padding-left: 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    list-style-type: none;
    background-color: white;
    position: absolute;
    right: 10px;
  }

  #span-toggle i {
    display: inline-block;
  }

  .user-collapse ul > li {
    color: #5f5f5f;
  }

  .user-collapse .collapsing {
    position: absolute;
    width: 210px;
    right: 0;
  }
}

@media (max-width: 768px) {
  .user-collapse ul {
    min-width: 100%;
    text-align: center;
    border: none;
    box-shadow: none;
    margin-left: 30px;
  }

  .user-collapse-ul > li {
    font-size: smaller;
  }

  .user-collapse-ul a {
    display: block;
    padding: 0 !important;
  }

  .user-collapse-ul a:hover {
    background-color: inherit;
  }

  #span-toggle {
    color: #899dc1;
  }

  #span-toggle i {
    display: block;
  }

  .user-collapse-ul li:hover span,
  .user-collapse-ul li:hover i {
    color: #2A3FAA;
  }

  .user-collapse-active span,
  .user-collapse-active i {
    color: #2A3FAA !important;
  }

  .user-collapse-ul li span,
  .user-collapse-ul li i {
    color: #899dc1;
  }

  .username {
    display: none;
  }

  .user-avatar {
    width: 100px;
    height: 100px;
  }
}

.clickable {
  cursor: pointer;
}

.WorkshopLoyaltyProgramModal_Container__2a7Jj {
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
}

.WorkshopLoyaltyProgramModal_paragraph__2dBoV {
  font-size: 14px;
  font-family: 'Montserrat-Regular';
}

.WorkshopLoyaltyProgramModal_paragraphBold__iN-Rx {
  font-size: 14px;
  font-weight: bold;
  font-family: 'Montserrat-Regular';
}

.WorkshopLoyaltyProgramModal_buttonPanel__1texg {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.WorkshopLoyaltyProgramRegister_Container__3L-VB {
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.WorkshopLoyaltyProgramRegister_paragraph__1I3XU {
  font-size: 14px;
  font-family: 'Montserrat-Regular';
}

.WorkshopLoyaltyProgramRegister_paragraphBold__2fxjz {
  font-size: 14px;
  font-weight: bold;
  font-family: 'Montserrat-Regular';
}

.WorkshopLoyaltyProgramRegister_buttonPanel__ScsIX {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.text-input-container {
  width: 100%;
  text-align: left;
}

.text-input-container .p-inputtext {
  font-size: 16px !important;
  border-radius: 0 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  font-family: 'Montserrat-Bold' !important;
}

.text-input-container .p-inputtext:focus {
  box-shadow: none !important;
  border-bottom: 1px solid #2A3FAB;
}

.text-input-container .p-inputtext:enabled:hover {
  border-bottom: 1px solid #2A3FAB;
}

.p-dropdown .p-dropdown-label.p-placeholder,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
global-filter form-control,
.custom-text-area.ui-inputtext.form-control
{
  font-family: 'Montserrat-Bold' !important;
}
/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.p-float-label input.p-state-filled-hack ~ label {
  top: -0.75em !important;
  font-size: 12px !important;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.user-notifications {
  text-align: right;
}

.user-notifications ul {
  margin-top: 24px;
  line-height: 30px !important;
  width: 400px;
  float: right;
  list-style-type: none;
  display: inline-block;
  font-size: large;
  text-align: left;
  padding-left: 0;
  padding-right: 10px;
  border: 2px solid #9e9e9d;
  border-radius: 0.75rem;
  background-color: white;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.user-notifications li {
  border-bottom: 1px solid #cfcfce;
  margin-left: 10px;
}

#user-notifications-toggle i {
  display: inline;
  vertical-align: middle;
  color: #2A3FAA;
  font-size: 24px;
}

#user-notifications-toggle i:hover {
  color: #b2b2b2;
}

#user-notifications-toggle i.active {
  background-color: #fcfcfc;
  color: #b2b2b2;
}

.user-notifications-collapse.collapse.show,
.user-notifications-collapse.collapsing {
  position: absolute;
  right: 15px;
}

.user-notifications-delete {
  text-align: center;
  cursor: pointer;
}

.user-notifications-delete:hover {
  background-color: #ebebeb;
}

.user-notification {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 80px;
}

.user-notification-icon-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 10px;
}

.user-notification-icon {
  color: #294174;
  text-align: center;
}

.user-notification-content-container {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.user-notification-content {
  color: #4d4d4d;
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  word-wrap: break-word;
  line-height: 1;
}

.user-notification-content-row {
  margin-bottom: 30px;
}

.user-notification-date {
  font-size: 10px;
  font-family: 'Montserrat-Regular';
  text-align: right;
  position: absolute !important;
  bottom: 0;
  right: 0;
}

@media (max-width: 500px) {
  .user-notifications ul {
    width: 300px !important;
    margin-right: -80px;
  }
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

#settings-icon-toggle i {
  display: inline;
  vertical-align: middle;
  color: #2A3FAA;
  font-size: 24px;
}

#settings-icon-toggle i:hover {
  color: #b2b2b2;
}

#settings-icon-toggle .active i {
  background-color: #CC0000;
  color: #b2b2b2;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.language-select {
  text-align: right;
}

#language-select-toggle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  justify-items: center;
  align-items: center;
}

.language-select ul {
  margin-top: 16px;
  line-height: 30px !important;
  width: 200px;
  float: right;
  list-style-type: none;
  font-size: 14px;
  text-align: left;
  padding-left: 0;
  border: 2px solid #9e9e9d;
  border-radius: 0.5rem;
  background-color: white;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Montserrat-SemiBold';
}

.language-select li {
  border-bottom: #dddfe2;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.language-select li:hover {
  background-color: #eff1f4;
}

#language-select-toggle {
  color: #2A3FAA !important;
  text-align: center !important;
  font-family: 'Montserrat-SemiBold';
  font-size: 14px;
  line-height: 1;
}

#language-select-toggle i {
  font-size: 24px;
}

#language-select-toggle i:hover {
  color: #b2b2b2;
}

#language-select-toggle i.active {
  background-color: #fcfcfc;
  color: #b2b2b2;
}

.language-select-collapse.collapse.show,
.language-select-collapse.collapsing {
  position: absolute;
  right: 15px;
}

.side-bar-container {
  float: left;
  position: fixed;
  height: 100% !important;
  width: 260px;
  padding-top: 60px;
  z-index: 3;
}

.side-bar-nav {
  height: 100%;
  padding-top: 30px;
  background-color: #fcfcfc;
  overflow-x: hidden;
  text-align: center;
  box-shadow: inset -4px 0px 4px -3px rgba(0, 0, 0, 0.15);
}

.side-bar-nav ul li a p span {
  font-family: 'Montserrat-Bold';
}

@media (max-width: 768px) {
  .side-bar-container {
    display: none;
  }
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

#update-data-sidebar {
  position: absolute;
  border-radius: 10px;
  width: auto;
  height: auto;
  top: 12rem;
  left: calc(10% + 260px); /* 10% +  left sidebar navigation width */
  right: 10%;
  padding: 0 !important;
}

#update-data-sidebar .top-bar {
  min-height: 50px;
  width: 100%;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  background-color: #fcfcfc;
  color: #242422;
  font-family: 'Montserrat-ExtraBold';
  font-size: 24px;
  text-align: center;
  padding-top: 8px;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: none;
}

#update-data-sidebar .ui-sidebar-close:hover {
  color: red !important;
  background-color: transparent !important;
}

#update-data-sidebar .ui-sidebar-close {
  vertical-align: middle;
  font-size: 24px;
  color: #242422;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 5px;
}

.p-row-toggler {
  background-color: #2A3FAB !important;
}
.p-row-toggler:focus {
  box-shadow: none !important;
}

.p-row-toggler {
  color: white !important;
}

tr {
  outline-color: white !important;
}

.p-sidebar-icons {
  background-color: #fcfcfc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.p-sidebar-close {
  color: #242422 !important;
  margin-right: 10px !important;
}
.p-sidebar-close:hover {
  background-color: inherit !important;
  color: grey !important;
}

.p-sidebar-close:focus {
  box-shadow: none !important;
}

@media (max-width: 380px) {
  #update-data-sidebar {
    left: 2%;
    right: 2%;
  }
} 

@media (min-width: 381px) {
  #update-data-sidebar {
    left: 10%;
  }
}

@media (min-width: 1369px) {
  #update-data-sidebar {
    left: calc(14% + 260px); 
    right: 14%;
  }
}

@media (min-width: 1680px) {
  #update-data-sidebar {
    left: calc(17% + 260px); 
    right: 17%;
  }
}

@media (min-width: 1920px) {
  #update-data-sidebar {
    left: calc(20% + 260px); 
    right: 20%;
  }
}


/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością

 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.content-wrapper-container {
  border-radius: 10px;
  background-color: #ffffff;
}

.content-wrapper-title {
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  background-color: #fcfcfc;
  color: #222220;
  text-align: center;
  min-height: 70px;
  padding: 23px 2rem 23px 2rem;
  border-bottom: 1px solid #eee;
}

.content-wrapper-title i {
  margin-right: 16px;
}

.content-wrapper-title span {
  font-family: 'Montserrat-Bold' !important;
  font-size: 24px;
  line-height: 1;
}

.content-wrapper-title img {
  margin-right: 26px;
}

.content-wrapper-content {
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.default-content-container {
  margin: 2rem 2rem 4rem 2rem;
}

.container-with-flex {
  display: flex;
  flex-direction: column;
}

.content-with-flex {
  flex: 1 1;
}

table td,
table thead tr th {
  font-size: smaller;
  padding: 5px !important;
}

.p-datatable-tbody .p-cell-data,
.p-datatable-emptymessage {
  color: #333333;
  line-height: 1;
  font-size: 14px;
  font-family: 'Montserrat-Light';
}

.p-datatable-emptymessage td {
  background-color: white !important;
}

.p-datatable .p-column-title {
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat-Bold';
  word-break: break-all;
}

.p-datatable-thead th {
  border: none;
  border-bottom: #c6c6c6 solid 2px;
  background-color: white;
  text-align: left;
  font-weight: bold;
  font-family: 'Roboto', 'Trebuchet MS', Arial, Helvetica, sans-serif;
  font-size: 1em;
}

.p-datatable th.ui-state-active {
  color: #000000;
}

.p-widget-header {
  border: none;
  background: transparent !important;
}

.p-widget-header .btn-secondary {
  background-color: #22ac92;
  border-color: #22ac92;
}

.p-widget-header .btn-secondary:hover {
  border-color: #22baa0;
  background-color: #22baa0;
}

.ui-widget-content {
  border: none !important;
}

.p-datatable-tbody td {
  border: none !important;
  word-wrap: break-word;
  font-size: 14px;
  font-family: 'Montserrat-Light';
}

.p-datatable-tbody tr {
  border: none !important;
}

tr td:nth-child(1) {
  background-color: #f1f1f1;
}

tr {
  background-color: #f9f9f9 !important;
}

.p-row-odd td:nth-child(1) {
  background-color: #f9f9f9 !important;
}

.p-row-odd {
  background-color: white !important;
}
.p-datatable-header {
  background-color: white !important;
  padding: 0 !important;
  border: none !important;
}
tr:hover,
tr .p-row-odd:hover {
  cursor: pointer;
  background-color: #f5f5f5 !important;
}

tr:hover td:nth-child(1),
tr .p-row-odd:hover td:nth-child(1) {
  background-color: #eaeaea !important;
}

.lp-col {
  width: 13%;
}

.email-col {
  width: 35%;
}

/*odd red rows*/
.red-row:nth-child(odd) td:nth-child(1) {
  background-color: rgba(255, 0, 0, 0.08) !important;
}

.red-row:nth-child(odd) {
  background-color: rgba(255, 0, 0, 0.08) !important;
}

.red-row:nth-child(odd):hover {
  cursor: pointer;
  background-color: rgba(255, 0, 0, 0.2) !important;
}

.red-row:nth-child(odd):hover td:nth-child(1) {
  background-color: rgba(255, 0, 0, 0.2) !important;
}

/*even red row*/
.red-row:nth-child(even) td:nth-child(1) {
  background-color: rgba(255, 0, 0, 0.12) !important;
}

.red-row:nth-child(even) {
  background-color: rgba(255, 0, 0, 0.12) !important;
}

.red-row:nth-child(even):hover {
  cursor: pointer;
  background-color: rgba(255, 0, 0, 0.2) !important;
}

.red-row:nth-child(even):hover td:nth-child(1) {
  background-color: rgba(255, 0, 0, 0.2) !important;
}

/*odd green rows*/
.green-row:nth-child(odd) td:nth-child(1) {
  background-color: rgba(0, 255, 0, 0.08) !important;
}

.green-row:nth-child(odd) {
  background-color: rgba(0, 255, 0, 0.08) !important;
}

.green-row:nth-child(odd):hover {
  cursor: pointer;
  background-color: rgba(0, 255, 0, 0.2) !important;
}

.green-row:nth-child(odd):hover td:nth-child(1) {
  background-color: rgba(0, 255, 0, 0.2) !important;
}

/*even green row*/
.green-row:nth-child(even) td:nth-child(1) {
  background-color: rgba(0, 255, 0, 0.12) !important;
}

.green-row:nth-child(even) {
  background-color: rgba(0, 255, 0, 0.12) !important;
}

.green-row:nth-child(even):hover {
  cursor: pointer;
  background-color: rgba(0, 255, 0, 0.2) !important;
}

.green-row:nth-child(even):hover td:nth-child(1) {
  background-color: rgba(0, 255, 0, 0.2) !important;
}

/*odd orange rows*/
.orange-row:nth-child(odd) td:nth-child(1) {
  background-color: rgba(255, 153, 0, 0.08) !important;
}

.orange-row:nth-child(odd) {
  background-color: rgba(255, 153, 0, 0.08) !important;
}

.orange-row:nth-child(odd):hover {
  cursor: pointer;
  background-color: rgba(255, 153, 0, 0.2) !important;
}

.orange-row:nth-child(odd):hover td:nth-child(1) {
  background-color: rgba(255, 153, 0, 0.2) !important;
}

/*even orange row*/
.orange-row:nth-child(even) td:nth-child(1) {
  background-color: rgba(255, 153, 0, 0.12) !important;
}

.orange-row:nth-child(even) {
  background-color: rgba(255, 153, 0, 0.12) !important;
}

.orange-row:nth-child(even):hover {
  cursor: pointer;
  background-color: rgba(255, 153, 0, 0.2) !important;
}

.orange-row:nth-child(even):hover td:nth-child(1) {
  background-color: rgba(255, 153, 0, 0.2) !important;
}

.bold-row {
  font-weight: bold;
}

.ui-row-toggler {
  font-size: 2em !important;
}

.expander-col {
  width: 5em;
}

.expanded-datatable th .ui-column-title {
  display: block !important;
}

.expandable-datatable td[colspan] {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-style: solid !important;
  border-width: 0 1px 0 1px !important;
  border-color: #f1f1f1 !important;
}

.working-hours-datepicker .ui-inputtext {
  background-color: transparent !important;
}

.data-table-button {
  margin-right: 0 !important;
}

.data-table-padding-class {
  padding: 30px 2rem 30px 2rem;
}

@media (max-width: 991px) {
  .data-table-padding-class {
    padding: 30px 1rem 30px 1rem;
  }

  .data-table-button {
    right: 1rem;
  }
}

@media (max-width: 576px) {
  .expander-col .ui-column-title {
    display: none !important;
  }
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.dropdown-content {
  border-radius: 0 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  box-shadow: none !important;
}

.dropdown-content label {
  padding-right: 40px !important;
  text-overflow: ellipsis;
  font-family: 'Montserrat-Regular';
  font-size: 16px !important;
  color: #000000;
}

.dropdown-content .p-dropdown-trigger {
  padding-top: 2px !important;
  border-left: 0 !important;
  color: #666666;
}
.field-label {
  margin-bottom: 3px;
}

.dropdown-content .p-dropdown:focus {
  box-shadow: none !important;
  border-bottom: 1px solid #2A3FAB;
}

.dropdown-content:hover {
  border-bottom: 1px solid #2A3FAB !important;
}

.dropdown-content .p-dropdown-clear-icon {
  color: #CC0000 !important;
  font-size: 1rem !important;
  right: 2rem !important;
}

.p-dropdown,
.p-dropdown-panel .p-dropdown-filter-container {
  width: 100% !important;
}

.phone-number-wrapper {
  text-align: left !important;
}

.phone-number-wrapper .phone-prefix {
  min-width: 4em !important;
  border-bottom: 1px solid #d6d6d6;
  text-align: center;
}

.phone-number-wrapper .phone-prefix:hover {
  border-bottom: 1px solid #2A3FAB;
}

.phone-number-wrapper .ui-inputgroup-addon {
  min-width: 3em !important;
}

.phone-number-wrapper .dropdown {
  margin-top: 4px;
}

.phone-number-wrapper .dropdown .clickable {
  font-family: 'Montserrat-Regular';
  line-height: 24px;
  color: #000000;
}

.phone-number-wrapper .dropdown .clickable i {
  color: #666666;
}

.phone-number-input {
  width: 100%;
}

@media (max-width: 576px) {
  .phone-number-input {
    width: calc(100% - 4em);
  }
}

.custom-text-area {
  width: 100%;
  font-family: 'Montserrat-Regular' !important;
  font-size: 16px !important;
  color: #000000 !important;
}

.custom-text-area:focus {
  box-shadow: none !important;
  border: 1px solid #2A3FAB !important;
}

.custom-text-area:enabled:hover {
  border: 1px solid #2A3FAB !important;
}

.custom-text-area .form-control:disabled,
.form-control[readonly] {
  color: #d4d4db;
  opacity: 0.35 !important;
  background-color: #ffffff !important;
}

.selected-workshop-wrapper .buttons button {
  width: 100%;
  border-color: #22ac92;
  background-color: #22ac92;
}

.selected-workshop-wrapper .buttons button:hover:enabled {
  border-color: #22baa0;
  background-color: #22baa0;
}

.selected-workshop-wrapper .buttons {
  padding: 0 10%;
}

.workshop-form h3 {
  color: #2A3FAB;
  font-family: 'Montserrat-SemiBold';
}

hr {
  border-width: 2px !important;
}

.workshop-form #search-box {
  margin-top: 10px;
  position: relative;
  background: #fff;
  border-radius: 2px;
  height: 40px;
  width: 50%;
  border: 1px solid transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
  transition-duration: 0.3s;
}

.workshop-form button {
  border-color: #22ac92;
  background-color: #22ac92;
}

.workshop-form button:hover:enabled {
  border-color: #22baa0;
  background-color: #22baa0;
}

.workshop-form {
  padding: 2% 10% 2%;
}

.workshop-form .w-100,
.workshop-form .ui-dropdown,
.workshop-form #error-msg,
.workshop-form #phone-input {
  width: 100% !important;
}

.workshop-form .p-dropdown-filter-container {
  width: 100%;
}

.update-workshop-form .w-100,
.update-workshop-form .ui-dropdown,
.update-workshop-form #error-msg {
  width: 100% !important;
}

.update-workshop-form textarea,
.update-workshop-form #text-area-error-msg,
.update-workshop-form #workshop-form-save-btn {
  width: 100% !important;
}

@media (max-width: 400px) {
  .workshop-form .w-100,
  .workshop-form .ui-dropdown,
  .workshop-form #error-msg {
    width: 75% !important;
  }

  .workshop-form #workshop-form-save-btn button {
    width: 100% !important;
  }

  .workshop-form #workshop-form-save-btn {
    text-align: center !important;
  }

  .update-workshop-form #workshop-form-save-btn button {
    width: calc(75% + 3em) !important;
  }

  .update-workshop-form #workshop-form-save-btn {
    text-align: left !important;
  }
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.avatar-container {
  position: relative;
  text-align: center;
  color: white;
}

.avatar-container img {
  max-width: 210px;
  max-height: 210px;
  border-radius: 50%;
}

.avatar-smaller-container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.avatar-wrapper-class label {
  font-family: 'Montserrat-SemiBold' !important;
  font-size: 16px !important;
}

.avatar-default-tip {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
  font-weight: bold;
  max-width: 200px;
}

.avatar-custom-tip {
  position: absolute;
  bottom: 0;
  left: calc(100% - 3em) !important;
  cursor: pointer;
  color: #9b9b9b;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.user-type-tile {
  background-color: white;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  text-align: center;
}

.active {
  background-color: #22baa0;
  color: white;
}

@media (min-width: 1025px) {
  .user-type-tile:hover {
    background-color: #22baa0;
    color: white;
  }
}

.selected-user-profile-data button,
.add-admin-form button {
  border-color: #22ac92;
  background-color: #22ac92;
}

.selected-user-profile-data button:hover:enabled,
.add-admin-form button:hover:enabled {
  border-color: #22baa0;
  background-color: #22baa0;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.service-edit-form .ui-dropdown {
  width: 100% !important;
}

.service-edit-form h2 {
  font-family: 'Montserrat-Semibold';
  color: #2A3FAB;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

#toggle-create-service-btn {
  width: calc(14rem + 10px) !important;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.category-header {
  cursor: pointer;
  background-color: #f0f0f0;
}

.category-header #description {
  font-size: smaller;
}

.category-header #categoryButtons {
  justify-content: space-between;
}

.category-header:hover {
  background-color: #e1e1e1;
}

.decision-btn {
  width: 2.5rem !important;
  height: 2.5rem !important;
  min-width: 2.5rem !important;
  min-height: 2.5rem !important;
}

.decision-buttons {
  font-size: smaller;
  word-break: keep-all;
}

.pending-services-info {
  font-size: smaller;
  color: #dc3545;
}

.category-header #toggle-icon {
  color: #2A3FAB !important;
}

#categoryButtons {
  display: flex;
  align-items: center;
}

@media (max-width: 1400px) {
  #categoryButtons {
    flex-direction: column;
    text-align: center;
  }
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.services-table thead tr th {
  border-bottom: none !important;
}

.services-table .p-datatable-even,
.services-table .p-datatable-odd,
.services-table .p-datatable-even td:nth-child(1),
.services-table .p-datatable-odd td:nth-child(1) {
  background-color: transparent !important;
}

.services-table .p-datatable-even:hover,
.services-table .p-datatable-odd:hover,
.services-table .p-datatable-even:hover td:nth-child(1),
.services-table .p-datatable-odd:hover td:nth-child(1) {
  background-color: #f0f0f0 !important;
}

.services-table table tbody tr:last-child {
  border-bottom: none !important;
}

#edit-service-btn {
  margin-right: 10px;
  float: right;
}

.service-button-section {
  display: flex;
  flex-direction: column;
  text-align: center;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

#create-category-btn,
#save-services-btn {
  border-color: #22ac92;
  background-color: #22ac92;
}

#create-category-btn:hover:enabled,
#save-services-btn:hover:enabled {
  border-color: #22baa0;
  background-color: #22baa0;
}

.parts-wrapper button {
  border-color: #22ac92;
  background-color: #22ac92;
}

.parts-wrapper button:hover:enabled {
  border-color: #22baa0;
  background-color: #22baa0;
}

.parts-content {
  display: inline-block;
  padding-left: 15px;
}

#parts-list .ui-datatable-even:hover td:nth-child(n) {
  background-color: white !important;
  cursor: auto !important;
}

#parts-list .ui-datatable-even td:nth-child(n) {
  background-color: white !important;
}

#parts-list .ui-datatable-odd:hover td:nth-child(n) {
  background-color: white !important;
  cursor: auto !important;
}

#parts-list .ui-datatable-odd td:nth-child(n) {
  background-color: white !important;
}

#parts-list .ui-datatable tfoot td.ui-state-default {
  border-top: 1px solid #c6c6c6;
  padding: 0 !important;
}

.needed-parts-custom-column {
  padding: 0 !important;
}

.needed-parts-custom-column .ui-dropdown {
  width: 90% !important;
  margin-left: 5%;
}

.needed-parts-custom-column .ui-cell-data {
  width: 100% !important;
}

.needed-parts-edit-btn {
  width: 90%;
  margin: 5%;
}

.p-connected-overlay-enter-done{
    z-index: 10000 !important;
}
/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

#page-minus,
#page-plus,
#mark-statute-valid-btn {
  border-color: #22ac92;
  background-color: #22ac92;
}

#page-minus:hover:enabled,
#page-plus:hover:enabled,
#mark-statute-valid-btn:hover:enabled {
  border-color: #22baa0;
  background-color: #22baa0;
}

.react-pdf__Document .react-pdf__Page {
  overflow-x: auto;
  border-color: black;
  border-style: solid;
  border-width: 1px;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

#add-statute-btn .ui-fileupload-choose .ui-button-icon-left {
  display: none;
}

#add-statute-btn .ui-fileupload-choose {
  font-family: 'Montserrat-Medium' !important;
  color: #ffffff !important;
  font-size: 15px !important;
  min-width: 90px !important;
  padding: 5px 10px 5px 10px !important;
  margin: 5px !important;
  border-color: #2A3FAB !important;
  background-color: #2A3FAB !important;
}

.choose-country .p-dropdown-label {
  padding-right: 2rem !important;
}

.p-button {
  background:#2A3FAB !important;
  border-color: #2A3FAB !important;
}

.p-button-label{
  font-family: 'Montserrat-Medium' !important;
  color: #ffffff !important;
}
.global-filter {
  font-family: 'Montserrat-Bold' !important;
  font-size: 16px !important;
  line-height: 1 !important;
  color: #b3b3b3 !important;
  border-color: #b3b3b3 !important;
  border-width: 2px !important;
  margin: 5px;
}

.distributor-form {
  padding: 2% 10% 2%;
}

.distributor-form .w-100,
.distributor-form .ui-dropdown,
.distributor-form #error-msg,
.distributor-form #phone-input {
  width: 100% !important;
}

.distributor-form .ui-dropdown-filter-container {
  width: 100%;
}

.edit-distributor-form .w-100,
.edit-distributor-form .ui-dropdown,
.edit-distributor-form #error-msg {
  width: 100% !important;
}

.edit-distributor-form textarea,
.edit-distributor-form #text-area-error-msg,
.edit-distributor-form #workshop-form-save-btn {
  width: 100% !important;
}

.distributor-form h3,
.edit-distributor-form h3 {
  color: #2A3FAB;
  font-family: 'Montserrat-SemiBold';
}

@media (max-width: 992px) {
  .distributor-form .w-100,
  .distributor-form textarea,
  .distributor-form .ui-dropdown,
  .distributor-form #error-msg,
  .distributor-form #distributor-buttons {
    width: 100% !important;
  }
}

@media (max-width: 400px) {
  .distributor-form #distributor-buttons button {
    width: 100% !important;
  }

  .distributor-form #distributor-buttons {
    text-align: center !important;
  }

  .edit-distributor-form #distributor-buttons {
    text-align: left !important;
  }
}

.react-datepicker-wrapper {
  max-width: 100% !important;
}

.react-datepicker__input-container {
  max-width: inherit !important;
}

.react-datepicker__input-container input {
  max-width: inherit;
}

.clear-icon {
  color: #888888;
  position: relative;
  font-size: 0.75rem !important;
}

.clear-icon:hover {
  cursor: pointer;
}

.p-inputgroup-addon {
  background-color: inherit !important;
  border-top: none !important;
  border-right: none !important;
}

.p-inputgroup-addon.disabled.clickable {
  cursor: default;
}

.p-inputgroup-addon.disabled.clickable .clear-icon:hover{
  cursor: default;
}
/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.date-time-picker-container .react-datepicker-wrapper,
.date-time-picker-container .react-datepicker__input-container {
  width: 100%;
}

.input-text {
  min-height: 34px;
}

.no-left-border {
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.number-format-field {
  background-color: #ffffff;
  border-radius: 0 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #c0c0c0;
  font-family: 'Montserrat-Regular' !important;
  font-size: 16px !important;
  color: #000000 !important;
}

.number-format-field:focus {
  box-shadow: none !important;
  border-bottom: 1px solid #2A3FAB;
}

.number-format-field:hover {
  border-bottom: 1px solid #2A3FAB !important;
}

.input-disabled {
  color: #a5a5a5 !important;
  border-bottom: 1px solid #f0f0f0 !important;
}

.input-disabled:hover {
  border-bottom: 1px solid #cfcaca !important;
}

.BarcodesTable_Container__1ykPd {
  overflow-x: auto;
  padding-bottom: 32px;
  padding-right: 32px;
}

.BarcodesTable_Header__2bt3u {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.BarcodesTable_GlobalFilter__3K6Cr {
  width: 20%;
  min-width: 150px;
}
.BarcodesTable_FilterContainer__3cNHY {
  min-width: 150px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.BarcodesTable_Check__3fjIU {
  border: 2px solid #b3b3b3 !important;
  margin: 0 5px 4px 10px !important;
  position: static !important;
}

.BarcodesTable_StateLabel__mdfZc {
  position: relative;
  margin-right: 7px;
}

.BarcodesTable_StateLabel__mdfZc::after {
  content: '';
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: #CC0000;
  display: inline-block;
  top: 2px;
  right: -5px;
}

.BarcodesTable_DotContainer__1YAUR {
  width: 100%;
  display: flex;
  justify-content: center;
}
.BarcodesTable_RedDot__qE5bD {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #CC0000;
}
.BarcodesTable_GreenDot__306ow {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #458a43;
}

.BarcodesTable_ClicableField__3sBjA {
  cursor: pointer;
}

.BarcodesDetails_Container__F8Y5q {
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BarcodesDetails_ColapsableSection__1BYC0 {
  background-color: #2c5c9a;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 5px 15px;
}

.BarcodesDetails_Toggle__37khY {
  width: 25px;
  height: 25px;
  border-radius: 13px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: #ffffff;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.BarcodesDetails_ToggleRotate__2ov2m {
  width: 25px;
  height: 25px;
  border-radius: 13px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: #ffffff;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.BarcodesDetails_ColapseBox__3EY2I {
  width: 100%;
}
.BarcodesDetails_Title__2oWRg {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  margin: 0 15px;
}

.BarcodesDetails_Entry__3kgZh {
  width: 100%;
  font-weight: bold;
  margin: 8px;
}

.BarcodesDetails_EntryCenter__3wkSe {
  width: 100%;
  font-weight: bold;
  margin: 8px;
  display: flex;
  align-items: center;
}

.BarcodesDetails_EntryData__1-iXi {
  font-weight: normal;
}

.BarcodesDetails_RedDot__osDWO {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #CC0000;
  margin-left: 10px;
}

.BarcodesDetails_GreenDot__1mgR6 {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #458a43;
  margin-left: 10px;
}

.BarcodesContainer_Container__1BLnT {
  display: flex;
  margin: 32px;
  justify-content: center;
}


.WarrantiesTable_Container__25dQO {
  overflow-x: auto;
}

.WarrantiesTable_Header__26a3Y {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.WarrantiesTable_GlobalFilter__2ZyDS {
  width: 20%;
  min-width: 150px;
}

.WarrantiesTable_DotRed__KVCxI {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #CC0000;
  margin: 0 auto;
}

.WarrantiesTable_DotGreen__1r384 {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #458a43;
  margin: 0 auto;
}

.WarrantiesTable_WarrantyBtn__61aYi {
  font-family: 'Montserrat-Medium' !important;
  color: #ffffff !important;
  font-size: 0.8em !important;
  white-space: normal !important;
  min-width: 90px;
  width: 95%;
  padding: 5px 10px 5px 10px !important;
  margin: 5px !important;
  border-color: #2A3FAB !important;
  background-color: #2A3FAB !important;
}
.WarrantyModal_Container__2oClf {
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.WarrantyModal_paragraph__qXNU6 {
  font-size: 14px;
  font-family: 'Montserrat-Regular';
}

.WarrantyModal_paragraphBold__2Jj5D {
  font-size: 14px;
  font-weight: bold;
  font-family: 'Montserrat-Regular';
}

.WarrantyModal_buttonPanel__2fPPB {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
.WarrantyEdit_Container__2Z-HM {
  margin: 32px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 20px;
  gap: 20px;
}

@media (max-width: 1195px) {
  .WarrantyEdit_Container__2Z-HM {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
}

@media (max-width: 580px) {
  .WarrantyEdit_Container__2Z-HM {
    grid-template-columns: 1fr;
  }
}

.WarrantyEdit_BtnTab__1pgve {
  cursor: pointer;
  background-color: #2A3FAB;
  min-width: 200px;
  width: 100%;
  height: 88px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.WarrantyEdit_BtnDisabledTab__1VyVA {
  cursor: pointer;
  background-color: #495057;
  min-width: 200px;
  width: 100%;
  height: 88px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.WarrantyEdit_BtnTabActive__3dofo {
  cursor: pointer;
  background-color: #1a2d56;
  min-width: 200px;
  width: 100%;
  height: 88px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.WarrantyEdit_TabTxt__1wYhR {
  color: #ffffff;
  font-family: 'Montserrat-Regular';
  font-weight: bold;
  font-size: 12px;
  margin-top: 18px;
  text-align: center;
}

.WarrantyEdit_Line__2mxyp {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #CC0000;
}

.WarrantyEdit_WarrantyButtons__2dFT5 {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 44px;
  padding-right: 44px;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.WarrantyStaticContainer_ImageContainer__2AU_q {
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.WarrantyStaticContainer_ImageList__2-zP- {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.WarrantyStaticContainer_ImageTitle__1n1Ii {
  font-family: 'Montserrat-SemiBold';
  margin-top: 9px;
  color: #2d4374;
  font-weight: 600;
  font-size: 18px;
  max-width: 135px;
  text-align: center;
}

.WarrantyStaticContainer_StaticContent__uzUG5 {
  padding-left: 96px;
  padding-right: 96px;
}

.WarrantyStaticContainer_StyledTextContent__xgy5e {
  font-family: 'Montserrat-Medium';
  font-size: 14px;
  margin-top: 45px;
  margin-bottom: 45px;
}

.WarrantyStaticContainer_TermsAcceptedText__3N9Yh {
  margin-top: 68px;
  font-family: "Montserrat-Medium";
  font-size: 16px;
  display: flex;
  align-items: center;
}

.termsAccepted .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #46b25b;
  background: #46b25b;
}

.termsAccepted .p-checkbox .p-checkbox-box.p-highlight.p-disabled {
  opacity: 1;
  border-color: #2A3FAB !important;
  background: #2A3FAB !important;
}

.termsAccepted {
  margin-top: 68px;
  font-family: "Montserrat-Medium";
  font-size: 16px;
  display: flex;
  align-items: center;
}

.WarrantyInstalationData_Container__6bQIz {
  padding: 32px;
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.WarrantyInstalationData_LabelContainer__UP_3W {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  min-height: 40px;
  width: 45%;
}

.WarrantyInstalationData_FirstSegmentCollapsedContainer__349Kz {
  padding: 64px 81px 81px 81px;
}
.WarrantyInstalationData_ColumnContainer__3XsZc {
  display: flex;
  justify-content: space-between;
}

.WarrantyInstalationData_Column__tSKLs {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 45%;
}

.WarrantyInstalationData_LabelName__HTJ_g {
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  color: #7e838c;
  text-align: left;
}

.WarrantyInstalationData_LabelValue__2SeCk {
  font-family: 'Montserrat-Medium';
  font-size: 16px;
  text-align: left;
}

.WarrantyInstalationData_Row__3or0o {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.WarrantyInstalationData_SecondSegmentCollapsedContainer__qx4yR {
  padding-top: 32px;
  padding-left: 46px;
  padding-right: 46px;
  padding-bottom: 46px;
  overflow-wrap: break-word;
}

.WarrantyInstalationData_SegmentTextStyle__1CHzJ {
  font-family: 'Montserrat-Medium';
  font-size: 14px;
  overflow-wrap: break-word;
}

.EmptyFieldPlaceholder_EmptyFieldPlaceholder__2N-TL{
    display: flex;
    justify-content: center;
    font-family: 'Montserrat-Medium';
    font-size: 16px;
}
.CollapseSection_Wrapper__1zebw {
  width: 100%;
  margin-bottom: 10px;
}

.CollapseSection_ColapsableSection__2zmyu {
  background-color: #2c5c9a;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 5px 15px;
}

.CollapseSection_Toggle__1zmkO {
  width: 25px;
  height: 25px;
  border-radius: 13px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: #ffffff;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.CollapseSection_ToggleRotate__1sk2K {
  width: 25px;
  height: 25px;
  border-radius: 13px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: #ffffff;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.CollapseSection_Title__2f3jl {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  margin: 0 15px;
}

.CollapseSection_ColapseBox__1p4Es {
  width: 100%;
}

.CollapseSection_ColapseBoxWithBorder__2qVt2 {
  width: 100%;
  border: 2px solid #2c5c9a;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.WarrantyNotes_WarrantyNotesContainer__3d8ux {
    margin-top: 46px;
    margin-left: 90px;
    margin-right: 90px;
    margin-bottom: 30px;
    overflow-wrap: break-word;
  }
.WarrantyBookReadonlyMode_Container__1yt_j {
  padding: 32px 240px 32px 240px;
}

@media (max-width: 1500px) {
  .WarrantyBookReadonlyMode_Container__1yt_j {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.WarrantyBookReadonlyMode_LabelContainer__32udz {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  min-height: 40px;
  width: 45%;
}

.WarrantyBookReadonlyMode_FirstSegmentCollapsedContainer__2_MOx {
  padding: 64px 81px 81px 81px;
}
.WarrantyBookReadonlyMode_ColumnContainer__3D3Zm {
  display: flex;
  justify-content: space-between;
}

.WarrantyBookReadonlyMode_Column__1DOdK {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 45%;
}

.WarrantyBookReadonlyMode_LabelName__2CEXx {
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  color: #7e838c;
  text-align: left;
}

.WarrantyBookReadonlyMode_LabelValue__2PzOv {
  font-family: 'Montserrat-Medium';
  font-size: 16px;
  text-align: left;
}

.WarrantyBookReadonlyMode_Row__3CG5i {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.WarrantyBookReadonlyMode_SecondSegmentCollapsedContainer__3slRb {
  padding-top: 32px;
  padding-left: 46px;
  padding-right: 46px;
  padding-bottom: 46px;
}

.WarrantyBookReadonlyMode_SegmentTextStyle__2w6uT {
  font-family: 'Montserrat-Medium';
  font-size: 14px;
}

.WarrantyBookEditMode_Container__RzPFz {
  padding: 32px 240px 32px 240px;
}

@media (max-width: 1500px) {
  .WarrantyBookEditMode_Container__RzPFz {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.WarrantyBookEditMode_NextBtnWrapper__3YIGc {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.WarrantyBookEditMode_Switch__3GHlr {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  border: solid 2px #c6c6c6;
  margin-left: 70px;
  margin-bottom: 22px;
}

.WarrantyBookEditMode_SwitchChecked__1EN4Y {
  border: solid 2px #2A3FAB;
  background-color: #2A3FAB;
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-left: 70px;
   margin-bottom: 22px;
}

.WarrantyBookEditMode_Switch__3GHlr:last-of-type .WarrantyBookEditMode_SwitchChecked__1EN4Y:last-of-type {
  margin-right: 32px;
}

.WarrantyBookEditMode_Unit__Rd21D {
  position: absolute;
  display: flex;
  top: -8px;
  right: -35px;
}

.WarrantyBookEditMode_BottomSection__1KJuo {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.WarrantyBook_Container__3Hqon {
}

.PeriodicInspections_Container__1NnxF {
  padding: 32px;
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PeriodicInspections_FirstPart__4duWM {
  width: 50%;
}

.PeriodicInspections_SecondPart__ZlRcM {
  min-width: 800px;
}

.PeriodicInspections_ThirdPart__3omxS {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  -webkit-column-gap: 40px;
          column-gap: 40px;
  margin-bottom: 45px;
}

@media (max-width: 1170px) {
  .PeriodicInspections_ThirdPart__3omxS {
    width: 100%;
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
  }

  .PeriodicInspections_FirstPart__4duWM {
    width: 100%;
  }
}

.PeriodicInspections_LabelContainer__3F8Vu {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  min-height: 40px;
}

.PeriodicInspections_LabelName__3ZTuk {
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  color: #7e838c;
  text-align: left;
}

.PeriodicInspections_LabelValue__2GEnQ {
  font-family: 'Montserrat-Medium';
  font-size: 16px;
  text-align: left;
}

.PeriodicInspections_SegmentCollapsedContainer__34L6T {
  padding: 32px 81px;
  overflow-x: auto;
}

.PeriodicInspections_Row__3Y4pp {
  width: 100%;
  justify-self: flex-start;
  font-family: 'Montserrat-Medium';
  font-size: 14px;
}

.PeriodicInspections_Label__2F4F2 {
  width: 100%;
  min-height: 94px;
  display: grid;
  grid-template-columns: 3fr 1fr 2.5fr;
  justify-content: center;
}

.PeriodicInspections_Yes__ozeXy {
  color: #458a43;
  margin: 0;
  width: 30px;
  justify-self: center;
  font-family: 'Montserrat-Bold' !important;
  font-size: 14px;
}

.PeriodicInspections_Not__25dx0 {
  color: #CC0000;
  margin: 0;
  width: 30px;
  justify-self: center;
  font-family: 'Montserrat-Bold' !important;
  font-size: 14px;
}

.PeriodicInspections_Description__Qr-ry {
  width: 400px;
  word-wrap: break-word;
  margin-bottom: 0;
  justify-self: flex-end;
  font-family: 'Montserrat-Medium';
  font-size: 14px;
}

.PeriodicInspectionNotStarted_Container__2cNOp {
  padding: 32px 82px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.PeriodicInspectionNotStarted_Text__1Nwaa {
  width: 100%;
  text-align: left;
}

.PeriodicInspectionsEdit_Container__2NqKl {
  padding: 32px 82px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.PeriodicInspectionsEdit_FirstPart__1xQpM {
  width: 50%;
}

.PeriodicInspectionsEdit_SecondPart__1NFsl {
  min-width: 800px;
}

.PeriodicInspectionsEdit_ThirdPart__FOevR {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  -webkit-column-gap: 40px;
          column-gap: 40px;
}

@media (max-width: 1170px) {
  .PeriodicInspectionsEdit_ThirdPart__FOevR {
    width: 100%;
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
  }

  .PeriodicInspectionsEdit_FirstPart__1xQpM {
    width: 100%;
  }
}

.PeriodicInspectionsEdit_ButtonBox__nEQRd {
  width: 100%;
  text-align: right;
}

.CheckBoxWithTextArea_Container__XDRiJ {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CheckBoxWithTextArea_Label__1pdmI {
  width: 40%;
  font-family: "Montserrat-Medium";
  font-size: 14px;
}

.CheckBoxWithTextArea_Dot__zGMw3 {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  border: solid 2px #c6c6c6;
  margin-right: 20px;
  
}

.CheckBoxWithTextArea_DotChecked__1r2qb {
  border: solid 2px #2A3FAB;
  background-color: #2A3FAB;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 20px;
}

.CheckBoxWithTextArea_DotBox__yBH-R {
  width: 80px;
  display: flex;
  align-items: center;
}

.CheckBoxWithTextArea_DotLabel__1fzYr {
  margin: 0;
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
}

.CheckBoxWithTextArea_DotsBox__1BIYr {
  display: flex;
}

.WorkshopLoyaltyProgramEditForm_WorkshopLoyaltyButtons__3oy-u {
    display: flex;
    justify-content: center;
  }

.WorkshopLoyaltyProgramEditForm_WorkshopLoyaltyFlexPointsAccrualPeriod__3YOPI {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.WorkshopLoyaltyProgramEditForm_Header__Mr1sp{
  margin-bottom: 1rem; 
  font-weight: bold; 
}
  
.jodit-toolbar__box{
    position: -webkit-sticky;
    position: sticky;
    top: 90px !important;
    z-index: 2 !important;
    background-color: white;
}

.jodit-workplace{
  background-color: white;
}

@media (max-width: 395px) {
  .jodit-status-bar {
    height: 50px !important;
}
}
.WorkshopLoyaltyProgramModal_Container__1cDL9 {
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.WorkshopLoyaltyProgramModal_paragraph__34mcv {
  font-size: 14px;
  font-family: 'Montserrat-Regular';
}

.WorkshopLoyaltyProgramModal_paragraphBold__1zeAS {
  font-size: 14px;
  font-weight: bold;
  font-family: 'Montserrat-Regular';
}

.WorkshopLoyaltyProgramModal_buttonPanel__1VW1x {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.upload-btn {
    font-family: 'Montserrat-Medium' !important;
    color: #ffffff !important;
    font-size: 15px !important;
    min-width: 90px !important;
    padding: 5px 10px 5px 10px !important;
    margin: 5px !important;
    height: 38px;
    border-color: #2A3FAB !important;
    background-color: #2A3FAB !important;
    border-radius: 3px;
  }

  .upload-btn .p-fileupload-choose {
    height: 28px;
  }

  .upload-btn .p-button-label{
    font-size: 15px;
  }
.ScoredProductsModal_Container__q75dO {
    width: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
  }
  
  .ScoredProductsModal_paragraph__22PyQ {
    font-size: 14px;
    font-family: 'Montserrat-Regular';
  }
  
  .ScoredProductsModal_paragraphBold__WtxJq {
    font-size: 14px;
    font-weight: bold;
    font-family: 'Montserrat-Regular';
  }
  
  .ScoredProductsModal_buttonPanel__2qajC {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
.PrizeProductsModal_Container__2-rOk {
    width: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
  }
  
  .PrizeProductsModal_paragraph__2sgAb {
    font-size: 14px;
    font-family: 'Montserrat-Regular';
  }
  
  .PrizeProductsModal_paragraphBold__2M2FG {
    font-size: 14px;
    font-weight: bold;
    font-family: 'Montserrat-Regular';
  }
  
  .PrizeProductsModal_buttonPanel__2aK4z {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
.statute-wrapper .react-pdf__Page {
    overflow-x: visible; 
    overflow-y: visible; 
}

.react-pdf__Page__annotations {
    display: none;
}

.react-pdf__Page__textContent {
    display: none;
}
.WorkshopLoyaltyProgramSelectedNotification_Container__L-O9O {
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.WorkshopLoyaltyProgramSelectedNotification_paragraph__2WWLa {
  font-size: 14px;
  font-family: 'Montserrat-Regular';
}

.WorkshopLoyaltyProgramSelectedNotification_paragraphBold__2_4z1 {
  font-size: 14px;
  font-weight: bold;
  font-family: 'Montserrat-Regular';
}

.WorkshopLoyaltyProgramSelectedNotification_buttonPanel__3NBj0 {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.EditNotificationDataForm_RowContainer__3HCr4 {
  display: inline-block;
}

.EditNotificationDataForm_Checkbox__3t9ag {
  margin-right: 20px;
  justify-content: center;
  display: inline-block;
}

.EditNotificationDataForm_CheckboxLabel__2Xmrb {
  margin-bottom: 0;
  margin-left: 10px;
}

.EditNotificationDataForm_SeverityLabel__19oFi {
  margin-top: 20px;
  margin-bottom: 10px;
}

.EditNotificationDataForm_RadioButton__24Ml6 {
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.EditNotificationDataForm_ColorBox__19Ex9 {
  width: 24px;
  height: 24px;
  border-radius: 3px;
  margin-left: 10px;
}

.WorkshopLoyaltyProgramNotifications_Btn__1qCJ5 {
  font-family: 'Montserrat-Medium' !important;
  color: #ffffff !important;
  font-size: 0.8em !important;
  white-space: normal !important;
  min-width: 90px;
  width: 95%;
  padding: 5px 10px 5px 10px !important;
  margin: 5px !important;
  border-color: #2A3FAB !important;
  background-color: #2A3FAB !important;
}
.PrizeProducts_Btn__9M2yC {
  font-family: 'Montserrat-Medium' !important;
  color: #ffffff !important;
  font-size: 0.8em !important;
  white-space: normal !important;
  min-width: 90px;
  width: 40%;
  padding: 5px 10px 5px 10px !important;
  margin: 5px !important;
  border-color: #2A3FAB !important;
  background-color: #2A3FAB !important;
}

.PrizeProducts_NewProductBox__3o6xU {
  justify-content: center; 
  display: flex;
  flex-direction: column;
  align-items: center
}
 .WorkshopList_Btn__3KLYA {
    font-family: 'Montserrat-Medium' !important;
    color: #ffffff !important;
    font-size: 0.8em !important;
    white-space: normal !important;
    min-width: 90px;
    width: 95%;
    padding: 5px 10px 5px 10px !important;
    margin: 5px !important;
    border-color: #2A3FAB !important;
    background-color: #2A3FAB !important;
  }
  

  .WorkshopList_Header__3mYVK {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 1.5rem;
}

.WorkshopList_FilterContainer__1qr6i {
    display: flex;
    flex-wrap: wrap;
    font-family: 'Lato';
}

.WorkshopList_Dropdown__3hzIk {
    width: 380px; 
    margin-right: 3rem; 
    font-weight: 400; 
}

.WorkshopList_RadioButtons__1PQIL {
    width: 180px;
}

.WorkshopList_SearchInput__2EdVI{
    width: 320px;
    position: relative;
    float: right;
    margin-top: 2rem;
}
.datePickerRadioButtonGroupWrapper .col-12:nth-child(1){
  display: none;
}

.datePickerRadioButtonGroupWrapper .fromToDatePicker {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.datePickerRadioButtonGroupWrapper .fromToDatePicker .dash {
  width: 40px;
  margin-left: 0;
  text-align: center;
  font-weight: normal;
}

.datePickerRadioButtonGroupWrapper .react-datepicker-wrapper{
  width: 200px;
}

.datePickerRadioButtonGroupWrapper .react-datepicker-wrapper input{
  height: calc(20px + 1rem);
}

.datePickerRadioButtonGroupWrapper #error-msg{
  display: none;
}

.datePickerRadioButtonGroupWrapper .mt-2{
  margin-top: 0rem !important;
}

.customRadioButtonWrapper {
  margin-top: 0.5rem;
}

.customRadioButtonWrapper .customRadioButtonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: calc(23px + 1rem);
}

.customRadioButtonWrapper .customRadioButtonRow >span{
  font-weight: normal;
  margin-left: 9px;
  width: 180px;
}

.workshop-list-selected-workshop-wrapper {
  padding: calc(2% + 16px) 10% 2%;
}


.ScoredProducts_Btn__2rQwl {
  font-family: 'Montserrat-Medium' !important;
  color: #ffffff !important;
  font-size: 0.8em !important;
  white-space: normal !important;
  min-width: 90px;
  width: 40%;
  padding: 5px 10px 5px 10px !important;
  margin: 5px !important;
  border-color: #2A3FAB !important;
  background-color: #2A3FAB !important;
}

.PointsAccuralPeriod_WorkshopLoyaltyFlexPointsAccrualPeriod__2IQZT {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .PointsAccuralPeriod_Header__2MHiM{
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  .PointsAccuralPeriod_LabelContainer__3gLPY {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    min-height: 40px;
    width: 100%;
    word-wrap: normal;
  }

  .PointsAccuralPeriod_LabelName__3s62n {
    font-family: 'Montserrat-Regular';
    font-size: 12px;
    color: #7e838c;
    text-align: left;
    font-weight: bold;
  }

  .PointsAccuralPeriod_LabelValue__1gvFr {
    font-family: 'Montserrat-Medium';
    font-size: 16px;
    text-align: left;
  }

  .PointsAccuralPeriod_LabelValueWithSpace__1Bmsr {
    font-family: 'Montserrat-Medium';
    font-size: 16px;
    text-align: left;
    margin-left: 5px;
  }

  .PointsAccuralPeriod_RenderPointsAccuralPeriod__2Chxx {
    display: flex;
    justify-content: center;
    font-weight: bold;
    padding-bottom: 2rem;
    margin-top: 2rem;
  }

  .PointsAccuralPeriod_ButtonSection__3AecY {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem
  }

  .PointsAccuralPeriod_InputField__3_uwv {
    width: 340px;
  }

  @media (max-width: 510px) {
    .PointsAccuralPeriod_InputField__3_uwv {
      width: 100%;
    }
  }
  
.Reports_WorkshopLoyaltyFlexReport__38Q5N {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
}
.Reports_Header__3pKHm {
  margin-bottom: 1rem;
  font-weight: bold;
  margin-top: 0px !important;
  padding-top: 2rem;
}

.Reports_LabelContainer__2g-R_ {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  min-height: 40px;
  width: 440px;
}

.Reports_LabelName__2kXtL {
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  color: #7e838c;
  text-align: left;
  font-weight: bold;
}

.Reports_LabelValue__2FPa1 {
  font-family: 'Montserrat-Medium';
  font-size: 16px;
  text-align: left;
}

.Reports_RenderPointsAccuralPeriod__1RbSB {
  display: flex;
  justify-content: center;
  font-weight: bold;
  padding-bottom: 2rem;
  margin-top: 2rem;
}

.Reports_ButtonSection__3XG1n {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  flex-wrap: wrap;
}

.WorkshopLoyaltyProgramHistoryList_Header__3GX1w {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 1.5rem;
}

.WorkshopLoyaltyProgramHistoryList_FilterContainer__1G5pa {
    display: flex;
    flex-wrap: wrap;
    font-family: 'Lato';
}

.WorkshopLoyaltyProgramHistoryList_Dropdown__1gG6O {
    width: 380px; 
    margin-right: 3rem; 
    font-weight: 400; 
}

.WorkshopLoyaltyProgramHistoryList_RadioButtons__1cx1b {
    width: 180px;
}

.WorkshopLoyaltyProgramHistoryList_SearchInput__kHqcR{
    width: 320px;
    position: relative;
    float: right;
    margin-top: 2rem;
}
.WorshopLoyaltyProgram_TilesContainer__2aZTH {
    margin-left: 2rem;
    margin-right: 1rem;
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 576px) {
    .WorshopLoyaltyProgram_TilesContainer__2aZTH {
        margin-right: 2rem;
    }
  }
.distributor-profile .card {
  border: none;
  background-color: transparent !important;
}

.distributor-profile h3 {
  font-family: 'Montserrat-Medium';
  color: #2A3FAB;
}

.distributor-profile textarea {
  resize: none;
}

.distributor-profile-card {
  border-top: 1px solid #c6c6c6;
  background-color: transparent;
}

@media (max-width: 400px) {
  .distributor-profile .distributor-details-form .phone-number-input {
    width: calc(100% - 3em) !important;
  }
}

.user-profile-container {
  margin: 2rem 2rem 4rem 2rem;
  width: 650px;
}

.profile-data {
  padding: 2rem;
}

.profile-data form input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.profile-data .form-control {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.profile-data .error-msg {
  font-size: small;
}

.profile-data img:hover {
  cursor: pointer;
}

.thin-user-tip {
  width: 90%;
  margin: 0 5% 0 5%;
}

.profile-data .ui-dropdown,
.profile-data .p-dropdown-panel .p-dropdown-filter-container {
  width: 100% !important;
}

.user-data-container {
  font-family: 'Montserrat-Regular';
  font-size: 16px;
  word-wrap: break-word;
  line-height: 1.2;
}

.user-data-container-error {
  word-wrap: break-word;
  line-height: 1.2;
  font-family: 'Montserrat-Regular';
  font-size: 16px;
  color: #CC0000;
  text-align: center !important;
  margin: 10px 0 20px 0;
}

.user-data-container-big {
  font-family: 'Montserrat-Regular';
  font-size: 18px;
  word-wrap: break-word;
  line-height: 1.5;
}

.user-data-label {
  font-family: 'Montserrat-SemiBold' !important;
}

@media (max-width: 992px) {
  .user-profile-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.car-form .ui-dropdown {
  width: 100% !important;
}

.Popup_ContainerAccept__lNRE2 {
  width: 75%;
  min-height: 200px;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 12px -4px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0 5px 12px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 5px 12px -4px rgba(0, 0, 0, 0.75);
  background-color: #bee3be;
  border: 1px solid #408140;
  padding: 20px;
  text-align: center;
}

.Popup_ContainerWarning__htOLH {
  width: 75%;
  min-height: 200px;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 12px -4px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0 5px 12px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 5px 12px -4px rgba(0, 0, 0, 0.75);
  background-color: #f4cbca;
  border: 1px solid #983a37;
  padding: 20px;
  text-align: center;
}

.Popup_ContainerDescription__CjP4g {
  width: 75%;
  min-height: 200px;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 12px -4px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0 5px 12px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 5px 12px -4px rgba(0, 0, 0, 0.75);
  background-color: #fff;
  border: 2px solid #2A3FAB;
  padding: 30px;
  text-align: center;
}

.Popup_MessageAccept__1UqjE {
  font-size: 20px;
  color: #408140;
}

.Popup_MessageWarning__2MT1Z {
  font-size: 20px;
  color: #983a37;
}

.CodeIssueForm_Container__3G5JD {
}

.CodeIssueForm_Line__2NYUV {
  margin-bottom: 10px;
}

.CodeIssueForm_Link__ZJ543 {
  display: block;
  margin-bottom: 10px;
  color: #495057;
}

.CodeIssueForm_Header__g1nsR {
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
}

.CodeScanner_Container__1U-Hp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.create-order-vendor-rating-container {
  margin: 50px auto auto auto;
  width: 45%;
}

.create-order-vendor-rating-container .card-header {
  text-align: center;
  font-weight: bold;
  font-size: large;
  color: white;
  background-color: #22baa0;
}

@media (max-width: 1511px) {
  .create-order-vendor-rating-container {
    width: 50%;
  }
}

@media (max-width: 1320px) {
  .create-order-vendor-rating-container {
    width: 55%;
  }
}

@media (max-width: 1200px) {
  .create-order-vendor-rating-container {
    width: 65%;
  }
}

@media (max-width: 768px) {
  .create-order-vendor-rating-container {
    margin: 0;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .create-order-vendor-rating-container .card-body {
    padding: 0.75rem;
  }
}

@media (max-width: 300px) {
  .create-order-vendor-rating-container .card-body {
    padding: 0.5rem;
  }
}

.valuation-inquiry-details-header {
  cursor: pointer;
  background-color: #2c5c9a;
  word-break: break-all;
  font-size: 16px;
  min-height: 40px;
  border-radius: 6px;
  color: #ffffff;
  font-family: 'Montserrat-SemiBold';
}

.valuation-inquiry-details-header span {
  font-family: 'Montserrat-Regular' !important;
}

.collapsed-header {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.valuation-inquiry-details-header:hover {
  background-color: #2A3FAB;
}

.valuation-inquiry-details-content {
  word-wrap: break-word;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 16px;
  font-family: 'Montserrat-Regular';
}

.details-border {
  border-radius: 10px !important;
  border: 1px solid #2c5c9a !important;
}

.customer-order-rating {
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
}

.customer-order-rating .ui-rating .fa-star {
  color: #f1cb00;
}

.customer-order-rating .ui-rating .fa-star-o {
  color: #d5d5dc;
}

.customer-order-rating .ui-rating a {
  margin-left: 0;
}

.customer-order-rating .rating-average {
  color: #f1cb00;
  margin-left: 5px;
  margin-right: 5px;
}

.customer-order-rating .ratings-count-info {
  color: #d5d5dc;
}

#toggle-icon {
  margin-right: 10px;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.react-datepicker__time-list-item.high-workload,
.react-datepicker__day.high-workload {
  color: red;
}

.react-datepicker__time-list-item.medium-workload,
.react-datepicker__day.medium-workload {
  color: #ffac04;
}

.react-datepicker__time-list-item.low-workload,
.react-datepicker__day.low-workload {
  color: #0e5510;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.subsection-tiles-container {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
  margin-top: 2rem !important;
}

.subsection-tile {
  flex-direction: column;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  text-align: center;
  font-family: 'Montserrat-Bold';
  font-size: 16px;
  color: #99A0AD;
  border-radius: 10px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 16px;
  justify-items: left;
  align-items: center;
}

.subsection-tile:hover {
  background-color: #D8DFED;
  color: #2A3FAA;
}

.subsection-tile-active {
  background-color: #D8DFED;
  color: #2A3FAA;
}

.subsection-tile-disabled {
  background-color: #6c757d;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.rc-steps {
  margin-top: 20px;
}

.rc-steps-item-icon {
  padding-top: 2px;
  font-family: 'ArialMT';
}

.rc-steps-item-icon span {
  font-size: 18px !important;
  font-weight: 800 !important;
}

.rc-steps-item.rc-steps-item-finish .rc-steps-item-tail::after {
  background-color: #2A3FAB !important;
}

.rc-steps-item.rc-steps-item-finish .rc-steps-item-icon {
  background-color: #2A3FAB !important;
  border-color: #2A3FAB !important;
}

.rc-steps-item-icon .rcicon-check::before {
  margin-top: 3px;
  color: white !important;
  content: '\f00c' !important;
  font-family: 'FontAwesome' !important;
}

.rc-steps-item.rc-steps-item-finish .rc-steps-item-icon .rc-steps-icon {
  color: #2A3FAB !important;
}

.rc-steps-item.rc-steps-item-process .rc-steps-item-icon {
  background-color: #2A3FAB !important;
  border-color: #2A3FAB !important;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.valuation-inquiry-form .ui-dropdown,
.valuation-inquiry-form .ui-dropdown-filter-container {
  width: 100% !important;
}

.valuation-inquiry-form #search-box {
  margin-top: 10px;
  position: absolute;
  background: #fff;
  border-radius: 2px;
  height: 30px;
  width: 300px;
  border: 1px solid transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
  transition-duration: 0.3s;
}

.valuation-inquiry-form .no-left-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.add-service-section-btn {
  background-color: transparent !important;
  border: none !important;
  color: #2A3FAB !important;
  font-size: 10px !important;
  font-family: 'Montserrat-Medium' !important;
  line-height: 20px !important;
  display: flex !important;
  box-shadow: none !important;
}

.add-service-section-btn i {
  font-size: 20px !important;
  margin-left: 5px;
}

.delete-service-section-btn {
  font-size: 10px !important;
  font-family: 'Montserrat-Medium' !important;
  color: #ffffff;
  background-color: #CC0000 !important;
  border-color: #CC0000 !important;
  line-height: 20px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.delete-service-section-btn i {
  margin-right: 5px;
  font-size: 20px !important;
}

.form-tooltip {
  width: 55vw !important;
  background-color: #f4917d !important;
  border: 1px solid #f4917d !important;
  color: white !important;
  font-size: 16px !important;
  font-family: 'Lato' !important;
  text-align: center;
  word-wrap: break-word;
  left: 10% !important;
}

.form-tooltip::after {
  border-top-color: #f4917d !important;
}

.form-tooltip-short {
  width: 20vw !important;
  background-color: #f4917d !important;
  border: 1px solid #f4917d !important;
  color: white !important;
  font-size: 16px !important;
  font-family: 'Lato' !important;
  text-align: center;
  word-wrap: break-word;
}

.form-tooltip-short::after {
  border-top-color: #f4917d !important;
}

.valuation-inquiry-form-radius-btn {
  min-width: 150px !important;
}

.accept-statute-container {
  width: 100%;
  min-height: 40px;
}

.accept-statute-content {
  display: flex;
  align-items: center;
  float: right;
}

@media (max-width: 991px) {
  .form-tooltip {
    width: 90% !important;
    left: 10px !important;
  }
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.valuation-inquiry-container {
  margin: 2rem 2rem 4rem 2rem;
}

.public-valuation-inquiry-container {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.valuation-inquiry-content {
  padding: 2rem 15rem 2rem 15rem;
  box-shadow: none !important;
}

.public-valuation-inquiry-content {
  padding: 3rem;
}

.valuation-inquiry-steps-mobile {
  display: none;
}

.public-valuation-container {
  margin: auto;
  width: 98%;
  padding-top: calc(60px + 1%);
  padding-top: -webkit-calc(60px + 1%);
}

@media (max-width: 1500px) {
  .valuation-inquiry-content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 992px) {
  .public-valuation-container {
    width: 96%;
    padding-top: calc(60px + 2%);
    padding-top: -webkit-calc(60px + 2%);
  }
}

@media (max-width: 768px) {
  .public-valuation-inquiry-container {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .valuation-inquiry-steps {
    display: none;
  }

  .valuation-inquiry-steps-mobile {
    display: inherit;
    padding-left: 45%;
  }

  .public-valuation-container {
    width: 94%;
    padding-top: calc(60px + 3%);
    padding-top: -webkit-calc(60px + 1%);
  }
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.rating-info-row {
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  font-family: 'Montserrat-Regular';
}

.rating-info-row.positive {
  background-color: #d3ecd9;
  border-color: #d3ecd9;
}

.rating-info-row.neutral {
  background-color: #e1e2e4;
  border-color: #e1e2e4;
}

.rating-info-row.negative {
  background-color: #f7d6d9;
  border-color: #f7d6d9;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.loyalty-program-info-row {
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
}

.loyalty-program-info-row-active {
  background-color: rgba(0, 255, 0, 0.2);
}

.CodeScanner_Container__WKgJW {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.accept-invite-text {
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Montserrat-Regular' !important;
  font-size: 16px !important;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.fc-event {
  font-size: 1.3em;
  font-style: italic;
  font-weight: bold;
}

.fc table td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.fc-button {
  border: 1px solid !important;
  background-color: #f5f5f5 !important;
  background-image: linear-gradient(to bottom, #fff, #e6e6e6);
  background-repeat: repeat-x;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25) !important;
  color: #212529 !important;
}

.fc-button:enabled:hover {
  background: #e6e6e6 !important;
}

.fc-state-active {
  background-color: #cccccc !important;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.schedule-container {
  height: calc(
    100vh - 60px - 25px - 2rem
  ); /*Viewport - header - footer - container margins*/
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.subscription-plan-active {
  background-color: rgba(0, 255, 0, 0.2) !important;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.inquiry-summary-card {
  border-color: #CC0000 !important;
  height: 240px !important;
}

.inquiry-summary-card-header {
  text-align: center;
  background-color: #CC0000 !important;
  color: #ffffff;
  font-size: 24px;
  font-family: 'Montserrat-SemiBold';
  line-height: 1;
}

.inquiry-summary-card-body {
  overflow-y: auto !important;
  font-family: 'Montserrat-Regular';
  font-size: 16px;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.valuation-inquiry-response-form .p-dropdown,
.valuation-inquiry-response-form .p-dropdown-filter-container {
  width: 100% !important;
}

.valuation-inquiry-response-form .p-dropdown.small {
  width: 25% !important;
}

.react-datepicker__input-container input {
  max-width: inherit;
}
.p-dropdown {
  width: 100%;
}

.ui-inputgroup {
  display: flex !important;
  justify-content: flex-start;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.dropdown-content {
  border-radius: 0 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  box-shadow: none !important;
  color: #2A3FAB;
}

.dropdown-content label {
  padding-right: 40px !important;
  text-overflow: ellipsis;
  font-family: 'Montserrat-Regular';
  font-size: 16px !important;
  color: #000000;
}

.dropdown-content .p-dropdown-trigger {
  padding-top: 2px !important;
  border-left: 0 !important;
  color: #666666;
}

.dropdown-content .p-dropdown:focus {
  box-shadow: none !important;
  border-bottom: 1px solid #2A3FAB;
}

.dropdown-content:hover {
  border-bottom: 1px solid #2A3FAB !important;
}

.dropdown-content .p-dropdown-clear-icon {
  color: #CC0000 !important;
  font-size: 1rem !important;
  right: 2rem !important;
}

.p-multiselect-panel .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #2A3FAB;
  background: #2A3FAB;
}

.p-multiselect-label{
  color: #495057;
}


.p-multiselect {
  width: 100%;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.valuation-inquiry-response-content {
  margin: 0;
}

.valuation-inquiry-response-form-wrapper {
  width: 980px;
  margin-left: auto;
  margin-right: auto;
}

.valuation-inquiry-response-steps-mobile {
  display: none;
}

@media (max-width: 1400px) {
  .valuation-inquiry-response-form-wrapper {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .valuation-inquiry-response-steps {
    display: none;
  }

  .valuation-inquiry-response-steps-mobile {
    display: inherit;
    padding-left: 45%;
  }
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.workshop-profile-buttons {
  text-align: right;
}

.workshop-details-section {
  border-top: 1px solid #d4d4db;
  margin-bottom: 20px;
}

.workshop-details-section-title {
  font-family: 'Montserrat-Medium' !important;
  font-size: 20px !important;
  text-align: center !important;
  margin: 20px 0 20px 0;
}

.edit-workshop-form #search-box {
  margin-top: 10px;
  position: relative;
  background: #fff;
  border-radius: 2px;
  height: 40px;
  width: 50%;
  border: 1px solid transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
  transition-duration: 0.3s;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.workshop-profile-buttons {
  text-align: right;
}

.details-section-with-border {
  border-top: 1px solid #d4d4db;
  margin-bottom: 20px;
}

.details-section {
  border-top: 0px;
  margin-bottom: 20px;
}

.workshop-details-section-title {
  font-family: 'Montserrat-Medium' !important;
  font-size: 20px !important;
  text-align: center !important;
  margin: 20px 0 20px 0;
}

.edit-workshop-form #search-box {
  margin-top: 10px;
  position: relative;
  background: #fff;
  border-radius: 2px;
  height: 40px;
  width: 50%;
  border: 1px solid transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
  transition-duration: 0.3s;
}

.button-panel {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.workshop-loyalty-program-profile-container {
  display: contents;
}

.points-container {
  margin-bottom: 20px;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.containers {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
  margin-top: 0rem !important;
}

.public-DraftStyleDefault-block{
    min-height: 120px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 1em;
    padding-right: 1em;
}

.points-section {
  border-top: 0px;
  border-bottom: 1px solid #d4d4db;
  margin-bottom: 20px;
}

.points-section-with-border {
  border-top: 0px;
  border-top: 1px solid #d4d4db;
  margin-bottom: 20px;
}

.statute-wrapper .react-pdf__Page {
    overflow-x: visible; 
}

.react-pdf__Page__annotations {
    display: none;
}

.react-pdf__Page__textContent {
    display: none;
}

.cartQuantityInputWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartQuantityInputWrapper .p-inputnumber-input {
  text-align: center;
  width: 80px;
  font-family: 'Montserrat-Regular' !important;
  font-size: 14px !important;
}

.cartQuantityInputWrapper .button {
  padding: 0 20px;
  font-family: 'Montserrat-Regular' !important;
  font-size: 18px !important;
  cursor: pointer;
  padding-bottom: 2px;
}

.cartQuantityInputWrapper .button.disabled {
  cursor: default;
}

.WorshopLoyaltyProgram_TilesContainer__1xVqU {
    margin-left: 2rem;
    margin-right: 1rem;
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 576px) {
    .WorshopLoyaltyProgram_TilesContainer__1xVqU {
        margin-right: 2rem;
    }
  }
.sign-up-container {
  width: 700px;
  margin: 50px auto 50px auto;
}

.sign-up-container button {
  background-color: #22baa0;
  border-color: #22baa0;
}

.sign-up-container button:hover:enabled {
  background-color: #22bab0;
  border-color: #22bab0;
}

.sign-up-container .form-control {
  width: 100%;
  padding: 0;
}

.sign-up-container #error-msg {
  width: 100%;
}

.sign-up-container .error-msg span {
  font-size: small;
}

.sign-up-container form {
  margin: 0 10%;
}

.sign-up-container .terms-text {
  width: 100%;
}

.sign-up-container .captcha-container .row {
  display: block !important;
}

.sign-up-container .p-dropdown,
.sign-up-container .p-dropdown-panel .p-dropdown-filter-container {
  width: 100% !important;
}

.accept-terms-container {
  display: flex;
  flex-direction: row;
}

.terms-text {
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  line-height: 1;
}

@media (max-width: 768px) {
  .sign-up-container {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .sign-up-container form {
    margin: 0 5%;
  }
}

@media (max-width: 350px) {
  .sign-up-container .captcha-container {
    -webkit-transform: scale(0.83);
            transform: scale(0.83);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media (max-width: 300px) {
  .sign-up-container form {
    margin: 0 2%;
  }
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.user-activation-info-container {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
}

.user-activation-info-content {
  padding: 2rem 4rem 2rem 4rem;
  text-align: center;
}

.user-activation-info-text {
  font-family: 'Montserrat-Regular';
  font-size: 14px;
  margin-bottom: 2rem;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością

 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.login-btn {
  background-color: #2A3FAB !important;
  border-radius: 5rem !important;
  font-family: 'Montserrat-Bold';
  font-size: 16px;
  border: none !important;
  opacity: 1 !important;
  color: white;
}

.login-btn:hover {
  cursor: pointer;
  background-color: #233968 !important;
}

.login-register-link {
  font-family: 'Montserrat-Medium';
  font-size: 12px;
  color: #000000;
  text-align: center;
}

.login-container-col .p-inputtext {
  color: #2A3FAB;
}

.login-container-col .btn-link {
  font-family: 'Montserrat-Medium';
  font-size: 12px;
  color: #2A3FAB !important;
}

.login-container-col .btn-link:hover {
  color: #233968 !important;
}

.login-register-link span {
  cursor: pointer;
}

.google-btn {
  background-color: #d34023 !important;
  border-color: #d34023 !important;
}

.google-btn:hover {
  background-color: #cd4534 !important;
  border-color: #cd4534 !important;
}

.fb-btn {
  background-color: #2c5c9a !important;
  border-color: #2c5c9a !important;
}

.fb-btn:hover {
  background-color: #3a4a87 !important;
  border-color: #3a4a87 !important;
}

.social-button {
  display: flex !important;
  align-self: space-around !important;
  align-items: center !important;
  margin: auto !important;
}

.social-icon {
  padding-right: 5px;
  border-right: 1px solid white;
}

.social-button-label {
  margin-left: 5px;
  font-family: 'Montserrat-Medium';
  font-size: 10px;
}

.modal-backdrop.show {
  opacity: 0.2;
}

.modal-content {
  border: none !important;
}

.modal-content .btn-primary {
  background-color: #22baa0 !important;
  border-color: #22baa0 !important;
}
.modal-content .btn-primary:hover {
  background-color: #22ac92 !important;
  border-color: #22ac92 !important;
}

.addon-width {
  width: 3em;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.start-page-wrapper {
  width: 600px;
  text-align: center;
  margin: 3rem auto 3rem auto;
}

.start-page-wrapper #valuation-btn {
  background-color: #2A3FAB;
  border-color: #2A3FAB;
  margin-top: 36px;
  max-width: 300px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 40px;
  padding-left: 40px;
  font-family: 'Montserrat-Bold';
  font-size: 16px;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .start-page-wrapper {
    width: 90%;
  }
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.promotion-container {
  border: none;
  min-width: 50%;
}

.promotion-container .modal-content {
  min-height: 600px !important;
}

.promotion-header {
  justify-content: center !important;
}

.promotion-title {
  margin-bottom: 0;
}

.close-promotion-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.page-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
}

.right-btn {
  float: right;
}

.promotion-image {
  height: auto;
  width: auto;
  max-height: 100%;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.promotion-description {
  margin-top: 2rem;
}

.promotion-end-date {
  color: grey;
  font-size: smaller;
  margin-bottom: 50px;
}

/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.p-paginator a {
  border: none;
}

.p-paginator a span {
  color: #666 !important;
}

.p-paginator .p-paginator-page.ui-state-active {
  background-color: transparent !important;
  color: #666 !important;
}

.p-paginator-page {
  font-family: 'Montserrat-Regular' !important;
}

.p-paginator {
  border: none !important;
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: flex-end !important;
  margin-top: 50px !important;
  font-family: 'Montserrat-Regular' !important;
  color: #333333 !important;
}

.p-paginator-pages {
  border: 1px solid #c9c9c9;
  line-height: 1;
}

.p-paginator-element {
  padding-right: 1px !important;
  padding-left: 1px !important;
}

.p-paginator-left-content {
  margin-right: 10px !important;
}

.p-paginator .p-dropdown {
  margin-left: 0 !important;
  border-radius: 0 !important;
  border-color: #c9c9c9 !important;
  padding-left: 5px !important;
  background-color: #ffffff !important;
}

.p-paginator-left-content .p-dropdown-label {
  font-family: 'Montserrat-Regular' !important;
  color: #333333 !important;
  line-height: 1 !important;
  text-align: center !important;
}

.p-paginator .p-dropdown-trigger {
  border-left: none;
}

.p-paginator .p-dropdown-trigger span::before {
  color: #666666;
}

.workshop-sign-up-container {
  margin: 3rem auto 3rem auto;
  width: 65%;
}

.workshop-sign-up-container .card-header {
  text-align: center;
  font-weight: bold;
  font-size: large;
  color: white;
  background-color: #22baa0;
}

.workshop-sign-up-container button {
  background-color: #22baa0;
  border-color: #22baa0;
}

.workshop-sign-up-container button:hover:enabled {
  background-color: #22bab0;
  border-color: #22bab0;
}

.workshop-sign-up-container .form-control {
  width: 100%;
  padding: 0;
}

.workshop-sign-up-container #error-msg {
  width: 100%;
}

.workshop-sign-up-container .error-msg span {
  font-size: small;
}

.workshop-sign-up-container form {
  margin: 0 10%;
}

.workshop-sign-up-container .terms-text {
  width: 100%;
}

.workshop-sign-up-container .captcha-container .row {
  display: block !important;
}

@media (max-width: 768px) {
  .workshop-sign-up-container {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .workshop-sign-up-container form {
    margin: 0 5%;
  }
}

@media (max-width: 350px) {
  .workshop-sign-up-container .captcha-container {
    -webkit-transform: scale(0.83);
            transform: scale(0.83);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media (max-width: 300px) {
  .workshop-sign-up-container form {
    margin: 0 2%;
  }
}

