.upload-btn {
    font-family: 'Montserrat-Medium' !important;
    color: #ffffff !important;
    font-size: 15px !important;
    min-width: 90px !important;
    padding: 5px 10px 5px 10px !important;
    margin: 5px !important;
    height: 38px;
    border-color: #2A3FAB !important;
    background-color: #2A3FAB !important;
    border-radius: 3px;
  }

  .upload-btn .p-fileupload-choose {
    height: 28px;
  }

  .upload-btn .p-button-label{
    font-size: 15px;
  }