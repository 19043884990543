.WorkshopLoyaltyFlexReport {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
}
.Header {
  margin-bottom: 1rem;
  font-weight: bold;
  margin-top: 0px !important;
  padding-top: 2rem;
}

.LabelContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  min-height: 40px;
  width: 440px;
}

.LabelName {
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  color: #7e838c;
  text-align: left;
  font-weight: bold;
}

.LabelValue {
  font-family: 'Montserrat-Medium';
  font-size: 16px;
  text-align: left;
}

.RenderPointsAccuralPeriod {
  display: flex;
  justify-content: center;
  font-weight: bold;
  padding-bottom: 2rem;
  margin-top: 2rem;
}

.ButtonSection {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  flex-wrap: wrap;
}
