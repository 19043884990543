.valuation-inquiry-details-header {
  cursor: pointer;
  background-color: #2c5c9a;
  word-break: break-all;
  font-size: 16px;
  min-height: 40px;
  border-radius: 6px;
  color: #ffffff;
  font-family: 'Montserrat-SemiBold';
}

.valuation-inquiry-details-header span {
  font-family: 'Montserrat-Regular' !important;
}

.collapsed-header {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.valuation-inquiry-details-header:hover {
  background-color: #2A3FAB;
}

.valuation-inquiry-details-content {
  word-wrap: break-word;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 16px;
  font-family: 'Montserrat-Regular';
}

.details-border {
  border-radius: 10px !important;
  border: 1px solid #2c5c9a !important;
}

.customer-order-rating {
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
}

.customer-order-rating .ui-rating .fa-star {
  color: #f1cb00;
}

.customer-order-rating .ui-rating .fa-star-o {
  color: #d5d5dc;
}

.customer-order-rating .ui-rating a {
  margin-left: 0;
}

.customer-order-rating .rating-average {
  color: #f1cb00;
  margin-left: 5px;
  margin-right: 5px;
}

.customer-order-rating .ratings-count-info {
  color: #d5d5dc;
}

#toggle-icon {
  margin-right: 10px;
}
