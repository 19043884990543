/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

.text-input-container {
  width: 100%;
  text-align: left;
}

.text-input-container .p-inputtext {
  font-size: 16px !important;
  border-radius: 0 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  font-family: 'Montserrat-Bold' !important;
}

.text-input-container .p-inputtext:focus {
  box-shadow: none !important;
  border-bottom: 1px solid #2A3FAB;
}

.text-input-container .p-inputtext:enabled:hover {
  border-bottom: 1px solid #2A3FAB;
}

.p-dropdown .p-dropdown-label.p-placeholder,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
global-filter form-control,
.custom-text-area.ui-inputtext.form-control
{
  font-family: 'Montserrat-Bold' !important;
}