.public-DraftStyleDefault-block{
    min-height: 120px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 1em;
    padding-right: 1em;
}

.points-section {
  border-top: 0px;
  border-bottom: 1px solid #d4d4db;
  margin-bottom: 20px;
}

.points-section-with-border {
  border-top: 0px;
  border-top: 1px solid #d4d4db;
  margin-bottom: 20px;
}
